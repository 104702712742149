import React from 'react'
import { BodyContentWrapper, StickyHeader } from './Styled'

// This content is generated through https://github.com/mwilliamson/mammoth.js/
const EulaPolicyContent = () => {
  return (
    <BodyContentWrapper data-testid="eulaPolicyContent">
      <StickyHeader>
        <strong>
          Liminex Products Terms of Service and End User License Agreement
        </strong>
      </StickyHeader>
      <div>
        <p>Last Updated Date: April 24, 2023</p>
        <p>
          <strong>Introduction </strong>
        </p>
        <p>
          Welcome! Before using any of our Offerings, please take the time to
          review this combined Terms of Service and End User License Agreement
          (EULA), together with any applicable Order Form(s), the
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy</strong>
          </a>
          , and, if applicable, the
          <a
            href="https://edulastic.com/data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Data Processing Addendum </strong>
          </a>
          (collectively, the “Agreement”). Capitalized words have the
          definitions set forth throughout this Agreement, including in Section
          17 (Definitions).
        </p>
        <p>
          <strong>
            <em> When does this Agreement apply? </em>
          </strong>
          This Agreement governs the use of the Offerings and is a binding
          contract between School (sometimes referred to as
          &ldquo;School&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo;) and
          Liminex, Inc. doing business as GoGuardian, and acting on behalf of
          itself and its Affiliates (&ldquo;Liminex&rdquo;, &ldquo;we&rdquo;,
          &ldquo;us&rdquo;, or &ldquo;our&rdquo;). This Agreement is distinct
          from our
          <a
            href="https://edulastic.com/website-terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Website Terms of Service</strong>
          </a>
          , which governs only use of our Website.
        </p>
        <p>
          BY CLICKING AN “I AGREE” OR “I ACCEPT” BUTTON; EXECUTING AN ORDER FORM
          THAT REFERENCES THIS AGREEMENT; COMPLETING THE REGISTRATION PROCESS;
          OR INSTALLING OR USING ANY OFFERINGS IN ANY WAY, SCHOOL AGREES TO THE
          TERMS OF THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS
          ACCEPTING THIS AGREEMENT ON BEHALF OF A SCHOOL, SCHOOL DISTRICT,
          CORPORATION, ORGANIZATION OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL
          REPRESENTS AND WARRANTS THAT THEY ARE AT LEAST EIGHTEEN (18) YEARS OLD
          AND HAVE THE AUTHORITY, THE RIGHT, AND THE CAPACITY TO LEGALLY BIND
          SUCH SCHOOL, SCHOOL DISTRICT, CORPORATION, ORGANIZATION OR OTHER LEGAL
          ENTITY AND ITS AFFILIATES TO THIS AGREEMENT, IN WHICH CASE THE TERM
          “SCHOOL” SHALL REFER TO EACH SUCH ENTITY AND ITS AFFILIATES.
        </p>
        <p>
          You are free to reject this Agreement, but if you do not agree with
          all of the provisions of this Agreement, then you may not use any of
          our Offerings in any way.
        </p>
        <p>IMPORTANT NOTICES:</p>
        <ul>
          <li>
            Section 15.2 contains provisions governing how claims are resolved
            between us, including a requirement for binding arbitration and
            class action waiver.
          </li>
          <li>
            You understand that use of some of our Offerings may require the
            consent for monitoring and recording information and communications
            of all users on the Managed Devices or Accounts under Applicable
            Law. You represent and warrant that you have obtained such requisite
            consent.
          </li>
          <li>
            We may make non-material modifications to this Agreement or changes
            that expand our obligations, reduce your obligations, or introduce a
            new product offering immediately without notice to you. Otherwise,
            we will only modify or replace this Agreement, after providing
            notice to you in accordance with Section 16.1. You are responsible
            for regularly reviewing your Account and our Website for any changes
            to the Agreement.
          </li>
          <li>
            Your use of, and participation in, certain Offerings may be subject
            to additional terms (“Supplemental Terms”) and such Supplemental
            Terms will either be listed in this Agreement, an Order Form or will
            be presented to you for your acceptance when you sign up to use the
            supplemental Offerings. If this Agreement is inconsistent with the
            Supplemental Terms, the Supplemental Terms will control with respect
            to the Offerings with which it applies.
          </li>
        </ul>
        <p>
          i.{' '}
          <em>
            We have included, in italics at the beginning of each section,
            summaries that provide short explanations of the legal language in
            plain English to aid in understanding, but such summaries are not
            legally binding.
          </em>
        </p>

        <p>
          <strong> 1. GETTING ACCESS TO AND STARTED WITH OUR OFFERINGS </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains how to establish and maintain Account(s). This
            Section describes the importance of being mindful about the
            information and settings in an Account – the value of our tools is
            dependent on the accuracy of the information you provide and the
            proper selection of product settings.
          </em>
        </p>
        <p>
          We generally make our Offerings available on a paid subscription-based
          license. As further described in Section 4.1, from time to time and in
          its sole discretion, we may offer limited free, trial or beta
          Offerings at no additional charge. For the TutorMe Offerings, we may
          offer pre-paid tutoring hours for a subscription term, in addition to
          the paid subscription-based licensing model.
        </p>
        <p>
          <strong> 1.1 Ordering </strong>
        </p>
        <p>
          You may obtain Subscriptions to our Offerings either directly from us
          or one of our Affiliates or indirectly through one of our Authorized
          Resellers. If you purchase a Subscription to an Offering through an
          Authorized Reseller, you will enter into an Order Form directly with
          such Authorized Reseller and may be subject to additional terms as
          agreed upon between you and the Authorized Reseller. You expressly
          agree that this Agreement governs your access to and use of any
          Offering that you acquire from an Authorized Reseller, as well your
          relationship with us as to any products or services you purchase
          through an Authorized Reseller. You further agree that we and our
          Affiliates have the right to enforce the terms of this Agreement with
          respect to such purchases. You also acknowledge that no Authorized
          Reseller is authorized to provide warranties with respect to any
          Offerings in excess of those provided by us in this Agreement. The
          terms regarding any value-added services provided to you by an
          Authorized Reseller are solely between you and the Authorized Reseller
          and we have no responsibility for any such services.
        </p>
        <p>
          If during the Subscription Term, you would like to expand your base
          Subscription(s) to include additional licenses above your Licensed
          Capacity, please contact us so that we can send you an additional
          Order Form for those Add-Ons. If we do not hear from you and you
          deploy additional Licenses, we or, as applicable, an Authorized
          Reseller will send you an Order Form and invoice you for the
          subscriptions to the Add-Ons that you use above your Licensed
          Capacity. Add-Ons, once deployed and paid, will be rolled into your
          base Subscription.
        </p>
        <p>
          <strong> 1.2 Accounts </strong>
        </p>
        <p>
          Before accessing certain Offerings, you may be required to create an
          Account. When setting up your Account, you may link your Offerings
          with your Google, Microsoft, and/or Learning Management System (“LMS”)
          account for ease of sign on and, for certain Offerings, to pull in a
          directory of Managed Accounts or Devices. In linking your Account to
          your Google, Microsoft, and/or LMS account, you are granting us access
          to certain account information from your Google, Microsoft, and/or LMS
          account, as permitted under the applicable terms and conditions that
          govern your use of that Google, Microsoft, or LMS account.
        </p>
        <p>
          <strong> 1.2.1 Authorized Users </strong>
        </p>
        <p>
          Within your Account, you may have the ability to enable separate
          sub-accounts for Authorized Users. You are responsible for issuing and
          maintaining such sub-accounts only for appropriate Authorized Users,
          as well as issuing and maintaining appropriate permission settings for
          each Authorized User.
        </p>
        <p>
          <strong> 1.2.2 School Information </strong>
        </p>
        <p>
          When creating an Account, you agree to (a) provide true, accurate,
          current and complete information necessary to create the Account and
          to deploy the Offerings you have subscribed to on Managed Devices or
          Accounts; and (b) promptly update any such information to maintain its
          accuracy and completeness during the Term. Without limiting the
          generality of the foregoing, you agree to provide and maintain
          accurate, up-to-date and complete School Profile Information, and for
          Accounts, Authorized School Personnel Information, Personal Student
          Information, Parent/Guardian Information, and Association Data. You
          acknowledge and agree that proper implementation and operation of
          certain Offerings depends largely on the accuracy, completeness and
          timeliness of the Personal Student Information, Parent/Guardian
          Information and Association Information ingested into the product;
          therefore, it is of the utmost importance that you take great care in
          maintaining and updating this Personal Student Information,
          Parent/Guardian Information and Association Information at all times
          during the Term.
        </p>
        <p>
          You are responsible for ensuring the confidentiality and security of
          School Account Information, including protecting, and requiring your
          Authorized Users to protect, usernames and passwords associated with
          your Account, and notifying us immediately if you suspect or know that
          (1) a username and password is known by someone other than the
          applicable Authorized User; and/or (2) your Account (including any
          individual Authorized User account) has been compromised.
        </p>
        <p>
          <strong> 1.2.3 Account Settings </strong>
        </p>
        <p>
          You are responsible for selecting and updating the settings in the
          Offerings you subscribe to as you see fit and ensuring that the
          selections comply with all Applicable Law, as well as any guidelines
          and requirements you have established, including, with respect to use
          of the GoGuardian Offerings, for monitoring Managed Devices or
          Accounts and parents’ or guardians’ authorization of such monitoring.
          If you have questions about any settings in an Offering or your
          Account, such as permissions and settings associated with Managed
          Devices or Accounts, please visit, as applicable, the GoGuardian
          <a
            href="https://help.goguardian.com/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center </strong>
          </a>
          and/or contact
          <a href="mailto:support@goguardian.com">
            <strong> support@goguardian.com</strong>
          </a>
          , or visit the Pear Deck
          <a
            href="https://help.peardeck.com/en?__hstc=181186424.a331f9cd231b0501655e39b86932d339.1628617317261.1637174793540.1637256220337.25&amp;__hssc=181186424.1.1637256220337&amp;__hsfp=431394172"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center </strong>
          </a>
          and/or contact
          <a href="mailto:support@peardeck.com">
            <strong> support@peardeck.com</strong>
          </a>
          , or visit the Edulastic
          <a
            href="https://edulastic.com/help-center/?__hstc=181186424.a331f9cd231b0501655e39b86932d339.1628617317261.1637174793540.1637256220337.25&amp;__hssc=181186424.1.1637256220337&amp;__hsfp=431394172"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center </strong>
          </a>
          and/or contact
          <a
            href="mailto:support@assessment.peardeck.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> support@assessment.peardeck.com</strong>
          </a>
          , or visit the TutorMe
          <a
            href="https://help.tutorme.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center </strong>
          </a>
          and/or contact
          <a
            href="mailto:support@tutorme.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> support@tutorme.com</strong>
          </a>
          , or visit the Giant Steps
          <a
            href="https://support.giantsteps.app/s/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center </strong>
          </a>
          and/or contact
          <a
            href="mailto:support@giantsteps.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> support@giantsteps.app </strong>
          </a>
          .
        </p>
        <p>
          <strong> 1.3 Payment Terms </strong>
        </p>
        <p>
          Fees due for initial orders, any Add-Ons to, and any subscription
          renewals of Offerings shall be set forth in the applicable Order Form
          (“Fees”). Fees for Subscriptions you purchase directly from Liminex or
          one its Affiliates shall be paid within thirty (30) days of the date
          of our issuance of an invoice for such purchases, unless otherwise
          stated on an Order Form. Payment obligations for purchases made
          through an Authorized Reseller shall be as agreed upon by you and the
          Authorized Reseller.
        </p>
        <p>
          Fees do not include any taxes, levies, duties or similar governmental
          assessments of any nature, including, for example, value-added, sales,
          use or withholding taxes, assessable by any jurisdiction; you are
          responsible for paying all such taxes, levies, duties and assessments
          associated with purchases and transactions under this Agreement.
        </p>
        <p>
          Unless otherwise stated on an Order Form, Fees are paid in advance of
          each billing period. Payment obligations under this Agreement are
          non-cancelable and all Fees paid are non-refundable. Upon your
          cancellation or termination of any Subscription, you remain
          responsible for payment of all Fees allocable to the terminated
          portion of the Subscription Term referenced in the applicable Order
          Form without any refund owed to you, unless otherwise mutually agreed
          to in writing between you and us.
        </p>
        <p>
          Upon notice to you, we may increase any Fees specified in an Order
          Form, provided the increase will not become effective until the
          expiration of the current Subscription Term. We may increase any Fees
          that are not specified in an Order Form at any time, with or without
          notice to you. We may allow you to continue using a free, trial or
          beta Offering on a paid Subscription basis, but your continued use and
          your Subscription would be subject to a completed Order Form and
          payment of the applicable Fee.
        </p>
        <p>
          For certain Offerings, we will endeavor to notify you if your paid
          Account has delinquent fees. If delinquent fees are not paid, we will
          suspend your use of the Services.
        </p>

        <p>
          <strong> 2. OFFERINGS </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains more about the Offerings, including updates to
            such offerings and linkages with other third party integration
            software that we make available to you. Offerings may experience
            downtime or we may suspend access to Offerings in certain
            situations.
          </em>
        </p>
        <p>
          <strong> 2.1 Access and Updates to Offerings. </strong>
        </p>
        <p>
          We will make our Offerings to which you have subscribed available to
          you, subject to the terms of this Agreement. From time to time, we may
          also make updates to the Offerings available to you. You agree that we
          may automatically install or add updates, upgrades, and new features
          to the Offerings that we deem to be reasonable, beneficial to you,
          and/or reasonably necessary without advance notice to you. You
          acknowledge and agree that any obligation we may have to support
          previous version(s) of any Offering may end when we release an update,
          upgrade, and/or additional feature for the Offering. The license
          granted for a Subscription shall apply to any updates, upgrades,
          and/or additional features that are not distributed with a separate
          license or other agreement. If we make any material changes to an
          Offering, we will notify you within the Offering interface or by
          sending you an email.
        </p>
        <p>
          With respect to an Offering accessed through or downloaded from the
          Apple App Store, Google Play Store, or Microsoft Store (each, an “App
          Store”) (an “App Store Sourced Application”), you acknowledge and
          agree that the availability of the App Store Sourced Application is
          dependent on the App Store.
        </p>
        <p>
          <strong> 2.2 Downtime and Suspension of Offerings. </strong>
        </p>
        <p>
          You acknowledge that you may experience downtime (a) as a result of
          our conducting maintenance; or (b) in connection with a force majeure
          event (as described in Section 16.5). In addition, you acknowledge
          that we, may at our discretion, suspend your access, or suspend access
          of one of your Authorized Users, to the Offerings for your breach, or
          your Authorized Users’ breach, of this Agreement, if we have
          reasonable grounds to suspect that any School Account Information
          provided by you or any Authorized User is untrue, inaccurate, not
          current or incomplete, or if we deem it reasonably necessary to avoid
          or mitigate harm to us, you, any other of our customers or a third
          party if the Offerings were not suspended, or to comply, in our sole
          discretion, with legal requirements.
        </p>
        <p>
          <strong> 2.3 Integrations with Offerings. </strong>
        </p>
        <p>
          We may allow you to access certain third party software or offerings
          through your Account via an application programming interface (“API”)
          or other technical connection (such software or offering, an
          “Integration Offering”). Because you control whether to integrate and
          use an Integration Offering, you are solely responsible for all
          interactions with the Integration Offering, including ensuring
          appropriate privacy, contractual, and other protections with it as
          well as ensuring that you are complying with any terms you are
          required to agree with the provider of such Integration Offering.
          Integration Offerings are not “Offerings” under this Agreement and are
          not subject to any of the warranties, service commitments or other
          obligations with respect to Offerings hereunder. The availability of
          any Integration Offering through any of our Offerings does not imply
          our endorsement of or affiliation with the provider of such
          Integration Offering. We do not control any Integration Offering and
          will have no liability to you in connection with any Integration
          Offering. We have no obligation to monitor or maintain access to
          Integration Offerings, and may disable the ability to integrate with
          them or restrict access to any Integration Offerings at any time, with
          or without notice to you (though we, of course, will endeavor to
          provide notice of any such disabling whenever reasonably practicable
          under the circumstances). By using or enabling any Integration
          Offering, you are expressly permitting us and our Affiliates to
          disclose your School Profile Information, and, to the extent
          applicable to your Offering, your Authorized School Personnel
          Information, Personal Student Information, Parent/Guardian
          Information, Association Information or other information, including
          support requests and Log and Cookie Information to the extent
          necessary to utilize the Integration Offering. YOUR USE OF ANY
          INTEGRATION OFFERINGS IS AT YOUR OWN RISK AND IS SUBJECT TO ANY
          ADDITIONAL TERMS, CONDITIONS AND POLICIES APPLICABLE TO SUCH
          INTEGRATION OFFERINGS (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES OF
          THE PROVIDERS OF SUCH INTEGRATION OFFERINGS).
        </p>

        <p>
          <strong> 3. YOUR USE OF OFFERINGS AND YOUR RESPONSIBILITIES </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that Liminex licenses our Offerings to you for
            certain uses. Please use our Offerings responsibly and
            appropriately.
          </em>
        </p>
        <p>
          <strong> 3.1 License Grant to You; Restrictions </strong>
        </p>
        <p>
          We hereby grant to you a limited, non-exclusive, non-transferable,
          non-sublicensable, revocable license and right to use the specific
          Offering(s) as set forth in an Order Form and the related User
          Documentation, during the Subscription Term and solely for School’s
          internal business purposes.
        </p>
        <p>
          The rights granted to School in this Agreement are subject to the
          following restrictions: School shall not (a) license, sell, rent,
          lease, transfer, reproduce, distribute, host or otherwise commercially
          exploit any portion of any Offering or User Documentation, or
          otherwise provide access to any portion of any Offering or User
          Documentation to any third party (other than Authorized Users, when
          and as permitted herein); (b) frame or utilize framing techniques to
          enclose any trademark, logo, or other portion of any Offering
          (including images, text, page layout or form); (c) use any metatags or
          other “hidden text” using the names or trademarks of Liminex or any of
          its Affiliates; (d) modify, translate, adapt, merge, make derivative
          works of, disassemble, decompile, reverse compile or reverse engineer
          any part of any Offering or User Documentation; (e) use any manual or
          automated software, devices or other processes (including but not
          limited to spiders, robots, scrapers, crawlers, avatars, data mining
          tools or the like) to “scrape” or download data from any web pages
          contained in any Offering or User Documentation; (f) access any
          Offering or User Documentation in order to build a similar or
          competitive website, product or service; (g) access any Offering for
          purposes of monitoring its availability, performance or functionality,
          or for any other benchmarking or competitive purposes without our
          prior written permission; and (h) remove any copyright notices or
          other proprietary markings contained on or in any Offering or User
          Documentation. Any future release, update or other addition to any
          Offering shall be subject to this Agreement. We and our suppliers
          reserve all rights not granted in this Agreement. Any unauthorized use
          of an Offering terminates your right to use all Offerings.
        </p>
        <p>
          <strong> 3.2 School Responsibilities </strong>
        </p>
        <p>
          School will (a) be responsible for Authorized Users’ compliance with
          this Agreement (including any applicable Order Form) and the User
          Documentation, (b) be responsible for the accuracy, quality and
          legality of School Account Information, and the means by which School
          acquires School Account Information, Activity Information, Personal
          Student Information, Parent/Guardian Information, and Log and Cookie
          Information and School’s use of any such information, (c) use
          commercially reasonable efforts to prevent unauthorized access to or
          use of the Offerings, and notify us promptly of any such unauthorized
          access or use, (d) use the Offerings only in accordance with this
          Agreement, User Documentation, and all Applicable Law, and (e) comply
          with the terms of service of any Integration Offering with which
          School use an Offering. Any use of an Offering in breach of the
          foregoing by School or any Authorized User that in our judgment
          threatens the security, integrity, or availability of an Offering may
          result in immediate suspension of access to any or all Offerings.
        </p>
        <p>
          School acknowledges that it is solely responsible for compliance with
          any legal or policy obligations related to the protection of the
          well-being of its students and that it understands that certain
          Offerings are only intended to be a tool to assist School as part of a
          broader program intended to fulfill any legal or policy obligations,
          to the extent applicable. For example, the Liminex Parties make no
          representation or warranty that any of the GoGuardian Offerings, alone
          or in combination with any other efforts, will be effective in
          detecting or stopping suicide, violence to self or others, or any
          other kind of risky behavior by a student of School or any other
          individual. We are not responsible for, and School expressly waives
          any claim for liability against the Liminex Parties related to, any
          student or other individual’s death, illness, bodily injury,
          disability, emotional distress or other outcome. To the maximum extent
          permissible by law, such waiver shall extend to any School personnel,
          student, student family, estate or other third parties. To the extent
          that any Liminex Party is made a party to any dispute related to any
          such death, illness, bodily injury, disability, or other outcome,
          School shall indemnify us in accordance with Section 13, below.
        </p>
        <p>
          <strong> 3.3 General Acceptable Use Policy </strong>
        </p>
        <p>
          We need your help to ensure that the Offerings are used safely and
          appropriately. You agree, represent and warrant that you and your
          Authorized Users will not use any Offering:
        </p>
        <ul>
          <li>
            On any computers and/or accounts on which you do not have permission
            to operate and on which the Offerings cannot be legally and
            rightfully operated.
          </li>
          <li>
            To do anything, including posting or otherwise communicating any
            information that is abusive, harmful, threatening, harassing,
            libelous, bullying, stalking, or otherwise objectionable.
          </li>
          <li>
            To do anything unlawful, deceptive, misleading, illegal, unethical,
            malicious, or discriminatory.
          </li>
          <li>
            To intentionally violate another person’s privacy rights under
            Applicable Law.
          </li>
          <li>
            For commercial purposes (beyond School’s internal business
            purposes).
          </li>
          <li>
            To do anything to burden, compromise, or interfere with any
            Offering, its appearances, security, or functionality.
          </li>
          <li>
            In any way that jeopardizes the security of your or any other
            party’s account.
          </li>
          <li>
            To advertise, solicit, or transmit commercial advertisements,
            including without limitation, junk e-mail, spam, or any other
            unsolicited messages regardless of the medium (e.g., email, text,
            SMS, chat, etc.).
          </li>
        </ul>
        <p>
          School is responsible for any failure by any Authorized User to comply
          with this General Acceptable Use Policy.
        </p>

        <p>
          <strong> 4. SPECIAL TERMS FOR FREE, TRIAL AND BETA OFFERINGS </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that we may make free, trial, or beta
            offerings available for you to test and provide us with Feedback
            about whether an Offering meets your needs. Because these Offerings
            are different from paid Subscriptions, there are special terms for
            them.
          </em>
        </p>
        <p>
          <strong> 4.1 Free or Trial Subscriptions </strong>
        </p>
        <p>
          If we make an Offering available to you on a free or trial basis, it
          is so you can use the Offering before purchasing a Subscription to
          determine if it meets your needs. Trial subscriptions to an Offering
          shall be subject in all respects to the terms of this Agreement,
          except that to the extent permitted by Applicable Law, we (a) may
          discontinue the Offering or your ability to use it at any time, with
          or without notice and without any further obligations to you; (b)
          provide the Offering to you solely “AS IS” without any warranties of
          any kind; and (c) will have no liability for any harm or damages
          suffered by you or any third party in connection with the use or
          inability to use the Offering during the trial period.
        </p>
        <p>
          <strong> 4.2 Beta Offerings </strong>
        </p>
        <p>
          We sometimes make new Offerings or features within existing Offerings
          available on a beta basis (“Beta Offerings”). Beta Offerings are
          generally prerelease or untested products or features. Features or
          offerings labeled as “alpha,” “beta,” or “test” within the interface
          of an Offering are beta Offerings subject to this Section. Unless
          otherwise agreed in writing by us, any Offering or feature provided as
          a beta Offering shall be subject in all respects to the terms of this
          Agreement, except that to the extent permitted by Applicable Law, we
          (a) may discontinue the beta Offering or feature or your ability to
          use it at any time, with or without notice and without any further
          obligations to you (though we, of course, will endeavor to provide
          notice of any such disabling whenever reasonably practicable under the
          circumstances); (b) provide the beta Offering or feature to you solely
          “AS IS” without any warranties of any kind; and (c) will have no
          liability for any harm or damages suffered by you or any third party
          in connection with the use or inability to use the beta Offering or
          feature during the beta period. Additionally, as consideration for
          access to and use of any beta Offering, you agree to (1) treat the
          beta Offering as our Confidential Information; and (2) provide regular
          Feedback about the beta Offering, if requested.
        </p>

        <p>
          <strong> 5. TERM, TERMINATION </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains the duration of this Agreement as well as your
            and our’ obligations after this Agreement ends.
          </em>
        </p>
        <p>
          <strong> 5.1 Term </strong>
        </p>
        <p>
          Unless terminated earlier in accordance with the terms of this
          Agreement, the Term commences on the Effective Date and continues
          until all of your Order Forms and Subscription Terms expire.
        </p>
        <p>
          <strong> 5.2 Termination </strong>
        </p>
        <p>
          Either party may terminate this Agreement (a) if the other party is in
          material breach of this Agreement and fails to cure such breach within
          thirty (30) days after its receipt of notice thereof, or (b) upon
          thirty (30) days’ notice to the other party. School shall continue to
          be responsible for all Fees for the terminated Subscription Term and
          other Fees agreed to in the Order Form except to the extent School
          terminates this Agreement as a result of our uncured material breach
          or we terminate this Agreement without cause (i.e., not as a result of
          School’s uncured material breach), in which cases School shall not be
          obligated to pay for (and shall be entitled to a refund by us of) the
          Fees allocable to the unused portion of the Subscription Term.
        </p>
        <p>
          <strong> 5.3 Effect of Termination or Expiration </strong>
        </p>
        <p>
          Upon termination or expiration of this Agreement, you will have no
          rights to continue use of (or to provide your Authorized Users with
          access to) the Offerings or User Documentation, and you immediately
          will cease accessing and/or using the Offerings and User
          Documentation, including by, where applicable, erasing and/or
          uninstalling all downloaded or copied versions of any Offerings and
          User Documentation, and returning to us or destroying any accompanying
          User Documentation in your possession or control. The following will
          survive any expiration or termination of this Agreement: Sections 1.2,
          1.3 (for Fees incurred during the Term), 2.3, 3 (except as otherwise
          set forth therein), 5.2, 5.3, 6, 7, 8, 10, 11, 12, 13, 14, 15, and 16.
        </p>

        <p>
          <strong> 6. INTELLECTUAL PROPERTY </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that we ask that you please protect our and
            others’ intellectual property.
          </em>
        </p>
        <p>
          <strong> 6.1 Ownership </strong>
        </p>
        <p>
          We or our Affiliates (or their licensors) own all right, title and
          interest in and to the Offerings (including, but not limited to, all
          photographs, animations, statistics, graphics, text, and any other
          materials contained therein), the Liminex Information, including,
          without limitation, all intellectual property rights therein. Subject
          to the limited rights expressly granted to you under this Agreement,
          we and our Affiliates reserve all rights, title and interest in and to
          the Offerings and the Liminex Information, including, without
          limitation, all related intellectual property rights. The service
          marks, logos and product and service names of Liminex and its
          Affiliates (the “Liminex Marks”) are owned by Liminex and our
          Affiliates. You agree not to display or use any Liminex Marks in any
          manner without our prior written permission. Any trademarks, service
          marks and logos associated with an Integration Offering may be the
          property of the third party provider, and you should consult with
          their trademark guidelines before using any of their marks.
        </p>
        <p>
          <strong> 6.2 Feedback </strong>
        </p>
        <p>
          Any and all of your feedback about us or the Offerings, such as
          suggestions, whether made by you or any of your Authorized Users, for
          corrections, updates, alterations, changes, or modifications to the
          Offerings (“Feedback”) will be our property and you hereby assign any
          rights in such Feedback to us, without payment to you.
        </p>
        <p>
          <strong> 6.3 Protection of Our Rights </strong>
        </p>
        <p>
          You agree to protect our intellectual property and proprietary rights
          and any provider of an Integration Offering you access in connection
          with an Account, and notify us of any unauthorized access or use of
          the Offerings or Integration Offerings of which you become aware.
        </p>

        <p>
          <strong> 7. CONFIDENTIALITY </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that each party must respect each other’s
            Confidential Information.
          </em>
        </p>
        <p>
          As a reminder, our
          <a
            href="https://www.goguardian.com/productPrivacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy </strong>
          </a>
          explains our commitment to protecting School Account Information,
          Personal Student Information, Parent/Guardian Information, Activity
          Information and/or Log and Cookie Information, and nothing in this
          Section 7 limits the parties’ respective rights or obligations under
          the
          <a
            href="https://www.goguardian.com/productPrivacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy </strong>
          </a>
          or Section 8, below. A party will not disclose or use any Confidential
          Information of the other party except: (a) as reasonably necessary to
          perform its obligations or exercise any rights granted pursuant to
          this Agreement; (b) with the other party’s prior written permission;
          or (c) to the extent required by law or order of a court or other
          governmental authority or regulation. Each party agrees to protect the
          other party’s Confidential Information in the same manner that it
          protects its own Confidential Information of like kind, but in no
          event using less than a commercially reasonable standard of care
          consistent with Applicable Law. Notwithstanding any exceptions in the
          definition of Confidential Information, the parties agree to treat all
          Education Records as Confidential Information and disclosure thereof
          is allowed only to the extent permitted under FERPA.
        </p>

        <p>
          <strong> 8. PRIVACY AND DATA </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that the parties each have responsibilities to
            help protect the privacy of data ingested or maintained in the
            Offerings. The
          </em>
          <a
            href="https://www.goguardian.com/productPrivacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>
              <em> Product Privacy Policy </em>
            </strong>
          </a>
          <em> explains, in more detail, how we protect information. </em>
        </p>
        <p>
          <strong> 8.1 Our Privacy Responsibilities </strong>
        </p>
        <p>
          Our
          <a
            href="https://edulastic.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy</strong>
          </a>
          , incorporated herein by reference, explains how we collect, use,
          share, and safeguard School Account Information, Personal Student
          Information, Parent/Guardian Information, Activity Information and/or
          School Log and Cookie Information accessed, recorded and collected
          through the Offerings, including any free, trial or beta Offerings. We
          rely on consent obtained from School, acting as an agent of the
          parent(s) or legal guardian(s) of School’s students, as permitted by
          the Children’s Online Privacy Protection Act (“COPPA”) for using
          Offerings in connection with students under thirteen (13) years of
          age, if any, through a COPPA Notice and Disclosure Form we provide to
          you. For transparency and informational purposes, we also distribute
          this COPPA Notice and Disclosure Form to Subscription purchasing
          Schools with children of all ages, including ones who do not have
          students under thirteen (13) years of age. To the extent School is
          located in the European Union (“EU”), European Economic Area (“EEA”),
          or Switzerland, our
          <a
            href="https://edulastic.com/data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Data Processing Addendum </strong>
          </a>
          also applies to your use of the Offerings. School hereby authorizes us
          to use School Account Information in accordance with this Agreement,
          the
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy </strong>
          </a>
          and, to the extent applicable, the
          <a
            href="https://edulastic.com/data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Data Processing Addendum</strong>
          </a>
          .
        </p>
        <p>
          <strong> 8.2 School Privacy Responsibilities </strong>
        </p>
        <p>
          You represent and warrant that your collection, provision and use of
          School Account Information, Personal Student Information,
          Parent/Guardian Information, Activity Information and/or Log and
          Cookie Information through and in connection with the Offerings does
          not violate any Applicable Laws or rights of any third party. Without
          limiting the foregoing, to the extent you are using the Offerings, you
          represent and warrant that you have obtained all requisite consent for
          monitoring and recording information and communications, including the
          Activity Information. You are solely responsible for notifying the
          appropriate individuals about the existence of any Offerings on the
          Managed Devices or Accounts they use and/or in connection with their
          accounts associated with School’s Account and obtaining any required
          consent from such individuals in accordance with Applicable Law. Even
          if not required by Applicable Law, we recommend that you notify all
          users of Managed Devices or Accounts and their respective
          parents/guardians, teachers, and other appropriate staff about your
          use of Offerings. More specifically, we recommend that you provide
          such persons with a copy of our
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy</strong>
          </a>
          , our COPPA Notice and Disclosure Form, and with respect to GoGuardian
          Offerings, GoGuardian’s form Parental Letter, and any other parental
          information made available by us.
        </p>
        <p>
          <strong> 8.3 Educational Records; FERPA </strong>
        </p>
        <p>
          Where School is located in the United States and to the extent we
          collect, through the provision or maintenance of any Offerings, any
          information that constitutes an Education Record, the parties agree
          that we are acting as a “school official” with “legitimate educational
          interests” in School’s students’ Education Records under FERPA, or if
          applicable, is acting under another applicable FERPA exception listed
          in under FERPA regulation 34 C.F.R. § 99.31(a)(1), such as the
          “directory information” exception. School acknowledges and agrees that
          we may process School Account Information, Personal Student
          Information, Parent/Guardian Information, Activity Information and/or
          School Log and Cookie Information for the purpose of providing the
          Offerings and related functions. Our
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy </strong>
          </a>
          explains how we cooperate with School under FERPA to fulfill Education
          Records requests directed from School (or through School from
          parents/guardians or eligible students). School represents and
          warrants that it is authorized to process such information, including
          any Education Records contained therein, and make such information
          available to us as set out in this Agreement and the
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy</strong>
          </a>
          .
        </p>
        <p>
          <strong> 8.4 Data-Related Disputes </strong>
        </p>
        <p>
          School is solely responsible for resolving disputes between it and
          third parties regarding ownership or access to any School Account
          Information, and any Personal Student Information, Parent/Guardian
          Information, Activity Information and/or School Log and Cookie
          Information, including any dispute with any Authorized User, user of a
          Managed Device or Account, or their families. School acknowledges and
          agrees that we have no obligation to resolve or intervene in such
          disputes.
        </p>
        <p>
          <strong> 8.5 Disclosure of School Information </strong>
        </p>
        <p>
          You acknowledge, consent, and agree that we may access, preserve, and
          disclose your School Account Information, Personal Student Information
          and Parent/Guardian Information, Activity Information and/or School
          Log and Cookie Information if required to do so by law or in a good
          faith belief that such access, preservation, or disclosure is
          reasonably necessary to: (a) comply with legal process; (b) to enforce
          this Agreement or the
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy</strong>
          </a>
          ; (c) respond to claims that any content violates the rights of third
          parties; (d) respond to your requests for customer service; or (e)
          protect the rights, property, or personal safety of us, our users,
          and/or the public.
        </p>

        <p>
          <strong> 9. ALERTS AND NOTIFICATIONS </strong>
        </p>
        <p>
          <strong>
            This section applies to the GoGuardian Offerings only.
          </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that some GoGuardian Offerings are designed to
            generate alerts and/or have other notification features, and that,
            through your settings, you have some ability to control whether to
            receive certain alerts and/or notifications. It is important for you
            to understand these features and settings, and to review alerts and
            notifications carefully and promptly so that you can properly
            intervene to help students stay safe.
          </em>
        </p>
        <p>
          Some GoGuardian Offerings contain features that generate alerts and/or
          notifications for School or a designated Authorized User’s review and
          attention related to a user’s activity through a Managed Device or
          Account. These alerts and notifications are tools designed to help you
          support and protect the wellbeing of your students and school
          community. If you receive an alert and/or gain access to any
          information that triggers a legal obligation, such as a reporting or
          intervention duty for you (including, for an example, an alert from a
          GoGuardian Offering that one of your students is potentially at risk
          of suicide), it is your sole responsibility to fulfill that legal
          obligation and take any additional, responsible actions necessary to
          safeguard the students in compliance with Applicable Law. In addition,
          to the extent you elect not to receive one or more types of alerts
          and/or notifications in your settings, you are solely responsible for
          that decision. Unless otherwise specified in the Supplemental Terms,
          we do not review alerts generated through any of the Offerings or take
          any action based on these alerts or review your settings.
        </p>
        <p>
          Your escalation and notification list for such alert and/or
          notification feature in your account for a particular GoGuardian
          Offering may change from time to time, including because contact
          information changes (e.g., changes in School Profile Information, its
          Authorized School Personnel Information, Personal Student Information
          and Parent/Guardian Information (e.g., contact information, including
          an Authorized User’s email address, or changes in Parent/Guardian
          Information ), and Association Information, including, for example,
          the relationship of an Authorized School Personnel or a
          guardian/parent to a child/student changes (e.g., change in
          guardianship or educational rights holder). Without limiting the
          generality of Section 1.2.1, it is important that you maintain
          accurate and up-to-date Authorized School Personnel Information,
          Personal Student Information, Parent/Guardian Information, and
          Association Information to ensure the appropriateness and timeliness
          of any alerts and notifications generated through a GoGuardian
          Offering. It is also important that you remind parents/guardians to
          update School of any changes, including contact information and
          guardianship.
        </p>
        <p>
          Additionally, without limiting the generality of the other terms of
          this Agreement, if your School uses GoGuardian Beacon or GoGuardian
          Admin (the self harm smart alerts or Beacon starter function), your
          School represents and warrants that your School: (1) is solely
          responsible for having, to the extent legally required, an up-to-date
          and easily available suicide prevention policy; (2) is solely
          responsible for having, to the extent legally required, a necessary
          suicide prevention program; and (3) your School will notify
          parents/guardians about your School’s adoption of GoGuardian Beacon
          and educate them about suicide prevention (including, suicide risk
          factors, warning signs, and resources) before enabling the Guardian
          Notification feature (or similar functionality) of GoGuardian Beacon
          or GoGuardian Admin (the self harm smart alerts or Beacon starter
          function).
        </p>

        <p>
          <strong>
            10. CONTENT, COPYRIGHT POLICIES, AND COMMUNITY GALLERIES
          </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains how content is used in certain Offerings and
            the rights to such content.
          </em>
        </p>
        <p>
          <strong> 10.1 Content in the Services Generally. </strong>
        </p>
        <p>
          10.1.1 You understand that all information (such as data files, fonts,
          written text, computer software, music, audio files, image files or
          other sounds, photographs, videos or other images) which you may have
          access to as part of, or through your use of, any Offerings
          (“Content”) is the sole responsibility of the person from which such
          Content originated. All such Content that is uploaded into or
          generated in any Offerings by or on behalf of you or your Authorized
          Users shall be referred to herein as "Your Content".
        </p>
        <p>
          10.1.2 You should be aware that Content, other than Your Content, made
          available as part of any Offerings, including but not limited to
          licensed and sponsored-Content, may be protected by intellectual
          property rights which are owned by the third party that provided that
          Content to us (or by other persons or companies on their behalf). You
          may not modify, rent, lease, loan, sell, distribute or create
          derivative works based on such Content (either in whole or in part)
          unless you have been specifically told that you may do so by us or by
          the owners of such Content, in a separate agreement.
        </p>
        <p>
          10.1.3 We reserve the right (but shall have no obligation) to
          pre-screen, review, flag, filter, modify, refuse or remove any or all
          Content, including Your Content, from any Offering.
        </p>
        <p>
          10.1.4 You understand that by using any Offering, you may be exposed
          to Content that you may find offensive, indecent or objectionable and
          that, in this respect, you use any Offering at your own risk.
        </p>
        <p>
          10.1.5 You agree that you are solely responsible for (and that neither
          Liminex nor any Liminex Parties from which you purchased any Offerings
          have any responsibility to you or to any third party for) any Content
          that you create, transmit or display while using any Offering and for
          the consequences of your actions (including any loss or damage which
          any Liminex Party may suffer) by doing so.
        </p>
        <p>
          <strong> 10.2 License to Your Content </strong>
        </p>
        <p>
          10.2.1 You retain copyright and any other rights you already hold in
          Your Content that you submit, share, upload, post or display on or
          through, any Offering. Unless otherwise stated in a separate written
          agreement between Liminex and You, by submitting, sharing, uploading,
          posting, or displaying Your Content you hereby grant to Liminex and
          our Affiliates a worldwide, royalty-free, and non-exclusive license to
          reproduce, adapt, modify, translate, publish, publicly perform,
          publicly display, and distribute Your Content, solely for the purpose
          of enabling Liminex and our Affiliates to provide you with any
          Offering in accordance with the terms of this Agreement and the
          <a
            href="https://edulastic.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Product Privacy Policy</strong>
          </a>
          .
        </p>
        <p>
          10.2.2 You understand that, in performing the required technical steps
          to provide any Offering to our users, we may (a) transmit or
          distribute Your Content over various public networks and in various
          media; and (b) make such changes to Your Content as are necessary to
          conform and adapt that content to the technical requirements of
          connecting networks, devices, services or media. You agree that this
          license shall permit us to take these actions.
        </p>
        <p>
          10.2.3 You confirm and warrant that you have all the rights, power and
          authority necessary to grant the above license. You agree that you
          will not submit, share, upload, post or display any Content on or
          through, any Offering that is copyrighted, protected by trade secret
          or otherwise subject to third party proprietary rights, including
          privacy and publicity rights, unless you are the owner of such rights
          or have permission from their rightful owner to submit, share, upload,
          post or display such Content and to grant Liminex all of the license
          rights granted in this Section.
        </p>
        <p>
          <strong> 10.3 Copyright Policies </strong>
        </p>
        <p>
          10.3.1 It is our policy to terminate access privileges of any users
          who repeatedly infringe copyright(s) embedded or used in connection
          with our Offerings upon prompt notification to Liminex by the
          copyright owner or the copyright owner’s legal agent. Without limiting
          the foregoing, if you believe that your work has been copied and
          posted on or within the Offerings in a way that constitutes copyright
          infringement, please provide our Copyright Agent with the following
          information: (a) an electronic or physical signature of the person
          authorized to act on behalf of the owner of the copyright interest;
          (b) a description of the copyrighted work that you claim has been
          infringed; (c) a description of the location on or within the
          Offerings of the material that you claim is infringing; (d) your
          address, telephone number and e-mail address; (e) a written statement
          by you that you have a good faith belief that the disputed use is not
          authorized by the copyright owner, its agent or the law; and (f) a
          statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner’s behalf. Contact
          information for Liminex’s Copyright Agent for notice of claims of
          copyright infringement is as follows:
        </p>
        <p>Copyright Agent</p>
        <p>Liminex, Inc.</p>
        <p>2030 E Maple Ave, Suite 100</p>
        <p>El Segundo, CA 90245</p>
        <p>
          We may remove any Content from the Offering if we believe or have
          reason to believe such Content infringes the intellectual property
          rights of a third party. Without prior notice and at any time, in our
          sole discretion, we reserve the right to remove any Content, disable
          your ability to share or upload Content through the Offerings, or
          terminate your access to any Offerings (a) for uploading or sharing
          such Content in violation of this Agreement; or (b) if, under
          appropriate circumstances, you are determined to be a repeat
          infringer.
        </p>
        <p>
          10.3.2 We reserve the right in our sole discretion to decide whether
          your conduct is inappropriate and whether it complies with this
          Agreement for violations other than copyright infringement, such as,
          but not limited to, pornography, obscene or defamatory material, etc.
          We may terminate your access for such inappropriate conduct in
          violation of this Agreement at any time and remove any such
          objectionable Content, without prior notice and at its sole
          discretion.
        </p>
        <p>
          <strong> 10.4 Third Party Websites and Resources </strong>
        </p>
        <p>
          10.4.1 The Offerings may include hyperlinks to other websites or
          resources that are controlled, owned or operated by third parties. You
          acknowledge and agree that we have no control over any such websites
          or resources and you access and use such websites and resources at
          your own risk.
        </p>
        <p>
          10.4.2 Without limiting the foregoing, you acknowledge and agree that
          neither Liminex, its Affiliates nor any Authorized Reseller are
          responsible for the availability of any such external sites or
          resources, and that we do not endorse any advertising, products or
          other materials on or available from such websites or resources.
        </p>
        <p>
          10.4.3 You acknowledge and agree that neither Liminex, its Affiliates
          nor any licensor or Authorized Reseller are liable for any loss or
          damage which may be incurred by you as a result of the availability of
          those external sites or resources, or as a result of any reliance
          placed by you on the completeness, accuracy or existence of any
          advertising, products or other materials on, or available from, such
          websites or resources.
        </p>
        <p>
          <strong> 10.5 Community Galleries and Gallery Content </strong>
        </p>
        <p>
          10.5.1 Certain Offerings may contain galleries (“Community
          Galleries”), which may include Content, including templates or
          questions, supplied by us or third parties, including other users of
          any Offerings (“Other Content Users”). Community Galleries include any
          Content, library or gallery that we choose at our discretion to make
          available to you as part of the Community Galleries (such content, the
          “Gallery Content”). As between you and the creators of Gallery
          Content, any intellectual property or proprietary rights remain with
          the creators.
        </p>
        <p>
          10.5.2 The Gallery Content: (a) is meant to serve as a suggestion
          only; and (b) is not a substitute for professional advice or specific,
          authoritative knowledge or direction. We do not promise that the
          Gallery Content will work for your purposes, or that it is free from
          viruses, bugs, or other defects. The Gallery Content is provided “as
          is” and without warranty of any kind. You alone bear the risk of using
          Gallery Content. No Liminex Party provides any express warranties,
          guarantees and conditions with regard to the Gallery Content. To the
          extent permitted under Applicable Law, the Liminex Parties expressly
          disclaim any and all implied warranties and conditions of
          merchantability, fitness for a particular purpose, workmanlike effort,
          title and non-infringement.
        </p>
        <p>
          10.5.3 If you choose to submit Your Content to become part of the
          Community Galleries (“Your Gallery Submission”), you direct and
          authorize Liminex and its affiliates to host, link to, and otherwise
          incorporate Your Gallery Submission into any Offering, and you hereby
          grant to Liminex and its Affiliates, and the Other Content Users an
          irrevocable, perpetual, worldwide, royalty-free, non-exclusive license
          to:
        </p>
        <p>(A) reproduce Your Gallery Submission or any component thereof;</p>
        <p>
          (B) create and reproduce derivative works of Your Gallery Submission
          [or any component thereof; or
        </p>
        <p>
          (C) publicly display and distribute copies of Your Gallery Submission
          or any components or derivatives thereof (“Your Gallery Submission
          License”).
        </p>
        <p>
          10.5.4 For the avoidance of doubt, we reserve, and you hereby grant
          us, the right to syndicate Your Gallery Submission and to use Your
          Gallery Submission in connection with any of the Offerings. While you
          may stop distributing Your Gallery Submission through the Community
          Galleries at any time, doing so will not in any way affect our right
          or the right of Other Content Users to exercise the rights granted to
          us as part of Your Gallery Submission License under this Agreement. To
          stop distributing Your Gallery Submission through the Community
          Galleries, (1) for Pear Deck Offerings, you must send an email to
          support@peardeck.com, in which case Your Gallery Submission will be
          removed in accordance with our standard procedures; and (2) for
          Edulastic Offerings and Giant Steps Offerings, you must delete Your
          Content or change Your sharing level permissions.
        </p>
        <p>
          10.5.5 You represent and warrant that (a) you own or have obtained the
          necessary legal rights to provide Your Gallery Submissions and will
          maintain these rights for as long as Your Gallery Submission is made
          available to Other Content Users; and (b) all of Your Gallery
          Submissions abide by the posted Program Policies.
        </p>
        <p>
          10.5.6 We claim no ownership over any of Your Gallery Submission. You
          retain copyright and any other rights, including all intellectual
          property rights, you already hold in Your Gallery Submissions. You
          agree that you are responsible for protecting and enforcing those
          rights and that we have no obligation to do so on your behalf.
        </p>
        <p>
          10.5.7 You agree that you are solely responsible for (and that we have
          no responsibility to you or to any third party for) any of Your
          Gallery Submissions that you submit. We are not in any way responsible
          for the subsequent use or misuse by any Other Content User or other
          third party who accesses Your Gallery Submission through any Offering
          or otherwise.
        </p>
        <p />

        <p>
          <strong> 11. APP STORE SOURCED APPLICATION </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains terms related to accessing and downloading an
            App Store Sourced Application.
          </em>
        </p>
        <p>
          <strong>
            11.1 Accessing and Downloading an App Store Sourced Application from
            an App Store
          </strong>
        </p>
        <p>
          You acknowledge that the Agreement is between you and Liminex or one
          of its Affiliates, and not with the App Store. We, not the App Store,
          are solely responsible for Offerings, the content thereof,
          maintenance, support services, and warranty therefor, and addressing
          any claims relating thereto (e.g., product liability, legal compliance
          or intellectual property infringement). You agree to comply with, and
          your license to use the Offerings is conditioned upon your compliance
          with all terms of agreement imposed by the applicable App Store when
          using the Offerings. You acknowledge that the App Store (and its
          subsidiaries) are third-party beneficiaries of the Agreement and will
          have the right to enforce it.
        </p>
        <p>
          <strong>
            11.2 Accessing and Downloading an App Store Sourced Application from
            the Apple App Store
          </strong>
        </p>
        <p>
          11.2.1 You acknowledge and agree that (a) the Agreement is concluded
          between you and us only, and not Apple, and (b) we, not Apple, are
          solely responsible for the App Store Sourced Application and content
          thereof. Your use of the App Store Sourced Application must comply
          with the Apple App Store Terms of Service. You agree to only use an
          App Store Sourced Application that is accessed through or downloaded
          from the Apple App Store (a) on an Apple-branded product that runs the
          iOS (Apple’s proprietary operating system) and (b) as permitted by the
          “Usage Rules” set forth in the Apple App Store Terms of Service.
        </p>
        <p>
          11.2.2 You acknowledge that Apple has no obligation whatsoever to
          furnish any maintenance and support services with respect to the App
          Store Sourced Application.
        </p>
        <p>
          11.2.3 In the event of any failure of the App Store Sourced
          Application to conform to any applicable warranty, you may notify
          Apple, and if applicable, Apple will refund the purchase price for the
          App Store Sourced Application to you and to the maximum extent
          permitted by applicable law, Apple will have no other warranty
          obligation whatsoever with respect to the App Store Sourced
          Application. As between the Liminex Parties and Apple, any other
          claims, losses, liabilities, damages, costs or expenses attributable
          to any failure to conform to any warranty will be the sole
          responsibility of the Liminex Parties.
        </p>
        <p>
          11.2.4 You and the Liminex Parties acknowledge that, as between the
          Liminex Parties and Apple, Apple is not responsible for addressing any
          claims you have or any claims of any third party relating to the App
          Store Sourced Application or your possession and use of the App Store
          Sourced Application, including, but not limited to: (a) product
          liability claims; (b) any claim that the App Store Sourced Application
          fails to conform to any applicable legal or regulatory requirement;
          and (c) claims arising under consumer protection or similar
          legislation.
        </p>
        <p>
          11.2.5 You and the Liminex Parties acknowledge that, in the event of
          any third-party claim that the App Store Sourced Application or your
          possession and use of that App Store Sourced Application infringes
          that third party’s intellectual property rights, as between the
          Liminex Parties and Apple, the Liminex Parties, not Apple, will be
          solely responsible for the investigation, defense, settlement and
          discharge of any such intellectual property infringement claim.
        </p>
        <p>
          11.2.6 The parties acknowledge and agree that Apple, and Apple’s
          subsidiaries, are third-party beneficiaries of the Agreement as
          related to your license of the App Store Sourced Application, and
          that, upon your acceptance of the terms and conditions of the
          Agreement, Apple will have the right (and will be deemed to have
          accepted the right) to enforce the Agreement as related to your
          license of the App Store Sourced Application against you as a
          third-party beneficiary thereof.
        </p>
        <p>
          11.2.7 Without limiting any other terms of the Agreement, you must
          comply with all applicable third-party terms of agreement when using
          the App Store Sourced Application.
        </p>
        <p />

        <p>
          <strong> 12. WARRANTIES, DISCLAIMER, AND RELEASE </strong>
        </p>
        <p>
          i.{' '}
          <em>
            This Section explains that we strive to provide you with our
            Offerings with a certain level of skill and care, but we cannot
            promise that our Offerings are perfect.
          </em>
        </p>
        <p>
          We provide the Offerings using a commercially reasonable level of
          skill and care, but we cannot make guarantees about the operation, use
          or results achieved through the use of the Offerings. ACCORDINGLY, TO
          THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, EACH OFFERING IS
          PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS,
          WITHOUT WARRANTY OF ANY KIND, AND SCHOOL’S USE IS AT ITS SOLE RISK.
          THE ENTIRE RISK OF SATISFACTORY QUALITY AND PERFORMANCE RESIDES WITH
          SCHOOL. THE LIMINEX PARTIES MAKE NO REPRESENTATIONS OR WARRANTIES OF
          ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND HEREBY
          SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING WITHOUT
          LIMITATION ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
          NON-INFRINGEMENT. THE LIMINEX PARTIES MAKE NO WARRANTY THAT ANY
          OFFERINGS WILL MEET SCHOOL’S REQUIREMENTS, WILL BE AVAILABLE ON AN
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE
          ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE,
          LEGAL, OR SAFE. SCHOOL IS SOLELY RESPONSIBLE FOR THE SCHOOL ACCOUNT
          INFORMATION, THE MEANS BY WHICH SCHOOL ACQUIRES SCHOOL ACCOUNT
          INFORMATION, ACTIVITY INFORMATION, PERSONAL STUDENT INFORMATION,
          PARENT/GUARDIAN INFORMATION, AND SCHOOL LOG AND COOKIE INFORMATION,
          AND ALL COMMUNICATIONS AND INTERACTIONS BASED ON THE OFFERINGS,
          INCLUDING ANY AUTOMATICALLY GENERATED ALERTS THAT SCHOOL MAY RECEIVE.
          WE ARE NOT A MEDICAL HEALTHCARE PROVIDER. SCHOOL UNDERSTANDS AND
          AGREES THAT LIMINEX AND THE OTHER LIMINEX PARTIES ARE UNDER NO
          OBLIGATION OTHER THAN TO PROVIDE THE OFFERINGS TO SCHOOL IN ACCORDANCE
          WITH THE TERMS OF THIS AGREEMENT.
        </p>
        <p>
          SCHOOL HEREBY RELEASES EACH OF THE LIMINEX PARTIES AND THEIR
          RESPECTIVE SUCCESSORS IN RIGHTS FROM ANY CLAIMS, DAMAGES, AND DEMANDS
          OF EVERY KIND ARISING OUT OF OR IN ANY WAY RELATED TO ANY CLAIMS MADE
          BY SCHOOL, ANY AUTHORIZED USER, ANY USER OF A MANAGED DEVICE OR
          ACCOUNT OR THEIR FAMILIES. IF APPLICABLE LAW REQUIRES ANY WARRANTIES
          WITH RESPECT TO ANY OFFERING, ALL SUCH WARRANTIES ARE LIMITED IN
          DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR HOW
          LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE EXCLUSION AND/OR
          LIMITATION MAY NOT APPLY TO SCHOOL. NOTHING IN THIS AGREEMENT
          EXCLUDES, RESTRICTS OR MODIFIES ANY GUARANTEE, WARRANTY, TERM OR
          CONDITION, RIGHT OR REMEDY IMPLIED OR IMPOSED BY ANY APPLICABLE LAW
          WHICH CANNOT LAWFULLY BE EXCLUDED, RESTRICTED OR MODIFIED.
        </p>

        <p>
          <strong> 13. INDEMNIFICATION </strong>
        </p>
        <p>
          School agrees, to the extent permitted by Applicable Law (and in the
          United States, the School’s state laws), to indemnify, defend and hold
          Liminex and the other Liminex Parties harmless from any losses, costs,
          liabilities and expenses (including reasonable attorneys’ fees)
          relating to or arising from (a) School’s failure to receive any
          consents or provide any notices required to be received or provided
          under Applicable Law in respect of all users of Managed Devices or
          Accounts, including for the monitoring and recording of Activity
          Information in connection therewith; (b) errors in the School Account
          Information, including Association Information, Parent/Guardian
          Information, Personal Student Information, and School’s selection of
          settings in the Offerings and maintenance of Authorized User accounts
          and their permission levels; (c) School’s acts or omissions relating
          to or regarding alerts generated through GoGuardian Beacon or
          GoGuardian Admin; (d) School’s failure to comply with Applicable Laws,
          including Applicable Mental Health Laws; (e) School’s violation of any
          rights of another party, including any users of Managed Devices or
          Accounts, (f) Your Content that you submit, share, upload, post or
          display on or to any Offering; (g) any use by other users of the
          Offerings of Your Content; and (h) any claim that Your Content
          violates any Applicable Laws or that it violates or infringes the
          rights of any third party. We reserve the right, at our own expense,
          to assume the exclusive defense and control of any third party claims
          for which we are entitled indemnification under this Section 13, and
          in any event, School agrees to cooperate with us in asserting any and
          available defenses.
        </p>

        <p>
          <strong> 14. LIMITATIONS AND EXCLUSIONS OF LIABILITY </strong>
        </p>
        <p>
          <strong> 14.1 Disclaimer of Certain Damages </strong>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL LIMINEX OR
          ANY OTHER LIMINEX PARTY BE LIABLE TO SCHOOL OR ANY THIRD PARTY FOR
          PERSONAL INJURY, PROPERTY DAMAGE, ANY LOST PROFITS, LOST DATA OR
          INFORMATION, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, LOSS OF
          GOODWILL, WORK STOPPAGE, COMPUTER OR DEVICE FAILURE OR MALFUNCTION, OR
          ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
          PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR
          SCHOOL’S USE OF, OR INABILITY TO USE, ANY OFFERING, EVEN IF LIMINEX OR
          THE OTHER LIMINEX PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. ACCESS TO, AND USE OF, THE OFFERINGS IS AT SCHOOL’S OWN
          DISCRETION AND RISK, AND SCHOOL WILL BE SOLELY RESPONSIBLE FOR ANY
          DAMAGE TO ANY DEVICES OR COMPUTER SYSTEMS USED TO ACCESS OR USE ANY
          OFFERING, OR LOSS OF DATA OR INFORMATION RESULTING THEREFROM.
        </p>
        <p>
          <strong> 14.2 Cap on Liability </strong>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO
          THE CONTRARY CONTAINED HEREIN, THE AGGREGATE LIABILITY OF LIMINEX AND
          ANY OTHER LIMINEX PARTIES FOR ANY DAMAGES ARISING FROM OR RELATING TO
          THIS AGREEMENT OR SCHOOL’S USE OR INABILITY TO USE AN OFFERING
          (WHETHER ORDERED DIRECTLY FROM A LIMINEX PARTY OR THROUGH AN
          AUTHORIZED RESELLER) FOR ANY CAUSE WHATSOEVER, AND UNDER ANY LEGAL
          THEORY, INCLUDING NEGLIGENCE, TORT OR STRICT LIABILITY, AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE FEES
          ACTUALLY PAID BY SCHOOL (WHETHER TO A LIMINEX PARTY OR AN AUTHORIZED
          RESELLER) FOR THE OFFERING GIVING RISE TO SUCH CLAIM FOR CALENDAR YEAR
          IN WHICH SUCH CLAIM AROSE. THE EXISTENCE OF MORE THAN ONE CLAIM WILL
          NOT ENLARGE THIS LIMIT. SCHOOL AGREES THAT OUR LICENSORS AND SUPPLIERS
          WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
          AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION
          OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR LIABILITY FOR
          DEATH OR PERSONAL INJURY, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY
          NOT APPLY TO SCHOOL.
        </p>
        <p>
          <strong> 14.3 Basis of the Bargain </strong>
        </p>
        <p>
          THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
          THE BASIS OF THE BARGAIN BETWEEN US AND YOU.
        </p>

        <p>
          <strong> 15. GOVERNING LAW AND DISPUTE RESOLUTION </strong>
        </p>
        <p>
          <strong> 15.1 Governing Law and Venue </strong>
        </p>
        <p>
          This Agreement and any dispute arising from or relating to this
          Agreement are governed by the laws of the state of California, United
          States, without regard to its conflict of law principles. You further
          agree to accept service of process by mail. To the extent the parties
          are permitted under this Agreement to initiate litigation in court,
          the parties consent to exclusive personal jurisdiction and venue in
          the courts located in Los Angeles County, California. If School is a
          United States public and accredited educational institution, domiciled
          in a state within the United States that expressly requires a
          different choice of law other than California law, then School’s
          state’s law will apply. If School is a United States public and
          accredited educational institution domiciled in a state within the
          United States that expressly requires venue or jurisdiction of a
          different state, then School’s state’s required venue and jurisdiction
          will apply.
        </p>
        <p>
          <strong>
            15.2 Dispute Resolution; Binding Arbitration; Class Action Waiver
            (“Arbitration Agreement”)
          </strong>
        </p>
        <p>
          In the event of a dispute, claim, or controversy arising out of or
          relating to this Agreement, any Offering, any person’s access to
          and/or use of the Offering, and/or the provision of content, features,
          and/or technology on or through the Offering (collectively, “Claims”),
          the parties must first give the other notice of the Claim. This notice
          must include a brief written statement with the name, address, and
          contact information of the party giving it, as well as the facts
          giving rise to the Claim and the relief requested. You must send any
          such notice to Liminex by email to legal@goguardian.com AND by U.S.
          Mail to Liminex, 2030 E Maple Ave Suite 100, El Segundo, CA 90245. If
          we have your contact information, we will send any such notice to you
          by U.S. Mail and your email address.
        </p>
        <p>
          The parties shall use reasonable, good faith efforts to resolve any
          Claim through consultation and good faith negotiations within thirty
          (30) days from the date that any notice of a Claim is sent. After
          thirty (30) days, you or we may resort to the other alternatives
          described in Section 15.2.1. Notwithstanding the foregoing, the notice
          requirement in this section and the 30-day negotiation period required
          shall not apply to Claims involving patents, copyrights, moral rights,
          trademarks, trade secrets or piracy or unauthorized use of an
          Offering.
        </p>
        <p>
          <strong> 15.2.1 Binding Arbitration </strong>
        </p>
        <p>
          <em> Arbitration Rules and Forum </em>Except as otherwise specifically
          set forth below, any Claims between you and us, if unresolved through
          informal consultation and negotiation pursuant to the preceding
          paragraph, shall be resolved by binding arbitration to be held in Los
          Angeles, California. Notwithstanding the foregoing, if School is a
          United States public and accredited educational institution domiciled
          in a state within the United States that expressly requires a
          different location for arbitration other than Los Angeles, California,
          then School’s state’s required location for binding arbitration will
          apply. If School that is a United States public and accredited
          educational institution and your consent to binding arbitration is
          expressly prohibited by the laws of the state within the United States
          in which School’s educational institution is domiciled, then this
          Section is hereby waived. The Federal Arbitration Act governs the
          interpretation and enforcement of this Arbitration Agreement. The
          arbitration shall be conducted by a single arbitrator, governed by
          JAMS pursuant to its Comprehensive Arbitration Rules &amp; Procedures
          (collectively, “JAMS Rules”), as modified by this Agreement, and
          administered by JAMS. The JAMS Rules and fee information are available
          at www.jamsadr.org or by calling JAMS at 1-800-352-5267. The
          arbitrator shall have exclusive authority to (a) determine the scope
          and enforceability of this Arbitration Agreement, and (b) resolve any
          dispute related to the interpretation, applicability, enforceability
          or formation of this Arbitration Agreement including, but not limited
          to, any claim that all or any part of this Arbitration Agreement is
          void or voidable. The arbitrator will decide the rights and
          liabilities, if any, of each party. The arbitration proceeding will
          not be consolidated with any other matters or joined with any other
          cases or parties.
        </p>
        <p>
          <em>
            Exceptions: Litigation of Intellectual Property and Small Claims
            Court Claims{' '}
          </em>
          concerning patents, copyrights, moral rights, trademarks, and trade
          secrets, and Claims of piracy or unauthorized use of any Offering
          shall not be subject to arbitration under this Section 15.2.1. In
          addition, the parties may choose to pursue a Claim in small claims
          court where jurisdiction and venue over the parties otherwise qualify
          for such small claims court and where the Claim does not include a
          request for any type of equitable relief.
        </p>
        <p>
          <em> Authority of Arbitrator: </em>In binding arbitration, the
          arbitrator shall have the authority (a) to grant motions dispositive
          of all or part of any Claim; (b) to award monetary damages; and (c) to
          grant any non-monetary remedy or relief available to an individual
          under Applicable Law, the arbitral forum’s rules, and this Agreement
          (including the Arbitration Agreement). The arbitrator shall issue a
          written award and statement of decision describing the essential
          findings and conclusions on which the award is based, including the
          calculation of any damages awarded. The arbitrator has the same
          authority to award relief on an individual basis that a judge in a
          court of law would have. The award of the arbitrator is final and
          binding upon you and us.
        </p>
        <p>
          <em> Confidentiality: </em>All aspects of the arbitration proceeding,
          including but not limited to the award of the arbitrator and
          compliance therewith, shall be strictly confidential. Both parties
          agree to maintain confidentiality unless otherwise required by law.
          This paragraph shall not prevent either party from submitting to a
          court of law any information necessary to enforce this Agreement, to
          enforce an arbitration award, or to seek injunctive or equitable
          relief.
        </p>
        <p>
          <strong> 15.2.2 Class Action Waiver </strong>
        </p>
        <p>
          THE PARTIES HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO
          SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. The
          parties are instead electing that all Claims not otherwise resolved
          informally as permitted above shall be resolved by arbitration under
          this Arbitration Agreement. An arbitrator can award on an individual
          basis the same damages and relief as a court and must follow this
          Agreement as a court would. However, there is no judge or jury in
          arbitration, and court review of an arbitration award is subject to
          very limited review.
        </p>

        <p>
          <strong> 16. MISCELLANEOUS </strong>
        </p>
        <p>
          <strong> 16.1 Changes to this Agreement </strong>
        </p>
        <p>
          Before we make a material change to this Agreement, we will notify you
          as described in this Section. We may make immaterial changes (e.g.,
          correcting a typographical error or another immaterial change) without
          notifying you. Please regularly review our Website and your Account
          for any changes. On our Website, we provide notice of any changes by
          posting the updated Agreement with a “Last Updated” date indicating
          the date of our most recent update. If we make material changes to
          this Agreement, we will also take an additional step (beyond posting
          on our Website) of notifying you of changes in another way that we
          believe is reasonably likely to reach you, such as emailing you at
          your email address associated with your Account, posting an
          announcement on our Website, or via a pop up in our Offering. Some
          changes may require your consent before further use of the Offerings
          is permitted. If you do not agree to any change(s), you agree to stop
          using the Offerings, and email us at terms@goguardian.com. Otherwise,
          your continued use of the Offerings after a change made in accordance
          with this provision constitutes your acceptance of such change(s).
        </p>
        <p>
          <strong> 16.2 Entire Agreement </strong>
        </p>
        <p>
          This Agreement, including any applicable Order Forms and any terms and
          policies that are incorporated into this Agreement by reference
          (including by reference to a URL), including any applicable
          Supplemental Terms, constitutes the entire agreement between you and
          the Liminex Parties with respect to the subject matter hereof and
          supersedes all prior agreements between you and the Liminex Parties
          and any other contractual obligations any Liminex Party or you may
          have to the other, whether written or oral, relating to the same
          subject matter. We reject additional or conflicting terms of School’s
          form-purchasing document you provide to us in connection with your
          procurement of access to any Offering. The headings of this Agreement
          are for readability only and do not constitute terms.
        </p>
        <p>
          <strong> 16.3 Language </strong>
        </p>
        <p>
          You agree that this Agreement and all related documents shall be drawn
          up in the English language.
        </p>
        <p>
          <strong> 16.4 Severability </strong>
        </p>
        <p>
          If any provision of this Agreement is invalid or unenforceable, such
          provision will be changed and interpreted to accomplish the objectives
          of such provision to the greatest extent possible under Applicable Law
          and the remaining provisions will continue in full force and effect.
        </p>
        <p>
          <strong> 16.5 Force Majeure </strong>
        </p>
        <p>
          Our failure to comply with any provision of this Agreement due to an
          act of God, hurricane, war, fire, riot, earthquake, terrorism, act of
          public enemy, actions of governmental authorities outside of our
          control (excepting compliance with applicable codes and regulations),
          or other force majeure event will not be considered a breach of this
          Agreement.
        </p>
        <p>
          <strong> 16.6 No Third Party Beneficiaries </strong>
        </p>
        <p>
          You agree that, except as otherwise expressly provided in this
          Agreement, there shall be no third party beneficiaries to this
          Agreement.
        </p>
        <p>
          <strong> 16.7 Electronic Communications </strong>
        </p>
        <p>
          The communications between you and Liminex or its Affiliates may take
          place via electronic means, whether you use an Offering or send us
          e-mails, or whether we post notices on or through any Offering or
          communicate with you via e-mail. For contractual purposes, you (a)
          consent to receive communications from us in an electronic form; and
          (b) agree that all terms and conditions, agreements, notices,
          disclosures, and other communications that we provide to you
          electronically satisfy any legal requirement that such communications
          would satisfy if it were to be in writing. The foregoing does not
          affect your statutory rights.
        </p>
        <p>
          <strong> 16.8 Assignment </strong>
        </p>
        <p>
          This Agreement, and your rights and obligations hereunder, may not be
          assigned, subcontracted, delegated or otherwise transferred by you
          without our prior written consent, and any attempted assignment,
          subcontract, delegation, or transfer in violation of the foregoing
          will be null and void. Subject to the foregoing, this Agreement shall
          bind and inure to the benefit of the parties, their respective
          successors and permitted assigns.
        </p>
        <p>
          <strong> 16.9 Notice </strong>
        </p>
        <p>
          Where we require that you or any Authorized Users provide an e-mail
          address, you are responsible for ensuring that we are provided with
          the most current e-mail address for the designated Authorized User. In
          the event that the last e-mail address provided to us is not valid, or
          for any reason is not capable of receiving any notices required/
          permitted by this Agreement, our dispatch of the e-mail containing
          such notice to such address will nonetheless constitute effective
          notice. You may give notice to us at the following address: 2030 E
          Maple Ave, Suite 100, El Segundo, CA 90245. Such notice shall be
          deemed given when received by us by letter delivered by nationally
          recognized overnight delivery service or first class postage prepaid
          mail at the above address.
        </p>
        <p>
          <strong> 16.10 Waiver </strong>
        </p>
        <p>
          Any waiver or failure to enforce any provision of this Agreement on
          one occasion will not be deemed a waiver of any other provision or of
          such provision on any other occasion.
        </p>
        <p>
          <strong> 16.11 Export Control </strong>
        </p>
        <p>
          You may not use, export, import, or transfer any Offering except as
          authorized by U.S. law, the laws of the jurisdiction in which you
          obtained the Offering, and any other Applicable Laws. By using any
          Offering, you represent and warrant that (a) you are not located in a
          country that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a “terrorist supporting” country
          and (b) you are not listed on any U.S. Government list of prohibited
          or restricted parties. You will not permit any Authorized User to
          access or use any Offering, or deploy any Offering on any device or
          account, in a U.S.-embargoed country or region or in violation of any
          U.S. export law or regulation.
        </p>
        <p />

        <p>
          <strong> 17. DEFINITIONS </strong>
        </p>
        <p>
          For the purposes of this Agreement, the following capitalized terms
          will have the meanings set forth for each of them below:
        </p>
        <p>
          17.1<strong> Add-on </strong>means any additional license to an
          Offering, beyond the original Licensed Capacity purchased, the parties
          agree to add to School’s Subscription during a Subscription Term.
        </p>
        <p>
          17.2<strong> Activity Information </strong>means information about
          online account and activity collected at the Authorized User level, in
          connection with Managed Devices or Accounts, including, for GoGuardian
          Offerings, chats (within GoGuardian Teacher), a student’s browsing
          history, IP address automatically collected by GoGuardian, online
          content, screenshots, and key input patterns (but not the actual
          inputs) to identify behaviors like gaming, and for Pear Deck Offerings
          and Giant Steps Offerings, information about the student’s activity
          within a presentation or session, including engagement with and any
          student content generated in the session, and, Your Content that is
          uploaded into or generated in any Offerings. This may include free
          text, multiple choice answers, drawings, or URLs. For Edulastic
          Offerings, information from or about any activity when an Authorized
          User is logged on, including audio recordings in an assessment may be
          collected. TutorMe may collect additional information, from or about
          any activity when an Authorized User is logged on including, chats,
          audio and video recording during virtual tutoring sessions, free text
          or drawings on the whiteboard during virtual tutoring sessions, and
          documents submitted to the TutorMe Writing Lab.
        </p>
        <p>
          17.3<strong> Affiliate </strong>means any entity that directly or
          indirectly controls, is controlled by, or is under common control with
          a party. “Control” for purposes of this definition means the power to
          direct or cause the direction of the management and policies of the
          subject entity, whether through equity ownership, a credit
          arrangement, franchise agreement or other contractual arrangement.
        </p>
        <p>
          17.4<strong> Applicable Law </strong>means any applicable federal and
          state laws, rules and regulations, applicable to the parties and/or
          the Offerings, including but not limited to the Family Educational
          Rights and Privacy Act (“FERPA”), and the Applicable Mental Health
          Laws.
        </p>
        <p>
          17.5<strong> Applicable Mental Health Law </strong>means any federal
          and state laws, rules and regulations applicable to School’s suicide
          and self-harm program and use of GoGuardian Beacon or GoGuardian Admin
          (self-harm smart alerts and/or Beacon Starter), including applicable
          law mandating staff training, certain suicide staff roles such as
          suicide prevention coordinators, and school prevention, intervention,
          and post-intervention policies.
        </p>
        <p>
          17.6<strong> Association Information </strong>means information
          associating a particular student or other user of a Managed Device or
          Account to a particular Managed Device or Account, as well as
          information about associations between any such user of a Managed
          Device or Account with School, an organizational unit or other
          grouping within the school (e.g., grade, class, cohort), a parent or
          guardian or a school official or Authorized School Personnel.
          Association Information does not include Activity Information.
        </p>
        <p>
          17.7<strong> Authorized Reseller </strong>means an entity authorized
          by Liminex or one of its Affiliates to sell Subscriptions to one or
          more Offerings under the terms of this Agreement and with which School
          has contracted directly to purchase the Offerings.
        </p>
        <p>
          17.8<strong> Authorized User </strong>means an individual who is
          authorized by School to use an Offering, for whom School has purchased
          or provisioned a Subscription, and to whom School (or, when
          applicable, Liminex or one of its Affiliates, at School’s request) has
          supplied access credentials (for Offerings utilizing authentication).
          Authorized Users include, for example, Authorized School Personnel and
          agents and third parties such as students and parents/guardians.
        </p>
        <p>
          17.9<strong> Authorized School Personnel </strong>means an Authorized
          User who is an employee, teacher, or official of a School or a
          School’s district.
        </p>
        <p>
          17.10<strong> Authorized School Personnel Information </strong>means
          information about Authorized School Personnel, including permission
          levels associated with the Authorized School Personnel, chat messages
          between students and teachers using an Offering, such as GoGuardian
          Teacher, unique account identifiers generated by a us, and other
          relevant unique identifiers.
        </p>
        <p>
          17.11<strong> Confidential Information </strong>means (a) any software
          utilized by us in the provision of any Offering and its respective
          source code; (b) each party’s business or technical information,
          including but not limited to the User Documentation, information
          relating to software plans, designs, costs, prices and names, business
          opportunities, personnel, research, development or know-how that is
          designated by the disclosing party as “confidential” or “proprietary”
          or the receiving party knows or should reasonably know is confidential
          or proprietary; and (c) any special pricing or other non-standard
          terms agreed to by the parties in an Order Form or other separate
          written document. Confidential Information will not include any
          information that: (w) is or becomes generally known to the public
          without breach of any obligation owed to the disclosing party; (x) was
          known to a party prior to its disclosure by the other party without
          breach of any obligation owed to the other party; (y) was
          independently developed by a party without breach of any obligation
          owed to the other party; or (z) was or is received from a third party
          without breach of any obligation owed to the other party.
        </p>
        <p>
          17.12<strong> Deidentified Information </strong>means aggregated and
          anonymized data which may be derived from School Account Information,
          Personal Student Information, Parent/Guardian Information, School Log
          and Cookie Information, or Activity Information; provided such
          Deidentified Information cannot reasonably be used to identify any
          Authorized User, user of a Managed Device or Account, or any other
          individual.
        </p>
        <p>
          17.13<strong> Education Records </strong>means “education records” as
          defined under the FERPA regulation 34 CFR § 99.3.
        </p>
        <p>
          17.14<strong> Account </strong>means an account created by or on
          behalf of School that is associated with one or more Offerings for
          which School has a Subscription, which is then used by School to
          manage access and use of the Offering(s) by School and its Authorized
          Users.
        </p>
        <p>
          17.15<strong> Liminex Information </strong>
          means (a) all Deidentified Information; and (b) any information
          collected by Liminex or its Affiliates independently and without
          access to, reference to or use of any School Account Information,
          Personal Student Information, Parent/Guardian Information, School Log
          and Cookie Information, or Activity Information.
        </p>
        <p>
          17.16<strong> Offering </strong>mean any product or service, including
          Professional Services, made available by Liminex or its Affiliates,
          including the GoGuardian Offerings, the Pear Deck Offerings, the
          Edulastic Offerings, the TutorMe Offerings, and the Giant Steps
          Offerings.
        </p>
        <p>
          17.16.1<strong> GoGuardian Offering(s) </strong>means Liminex’s suite
          of products and services, including Professional Services, provided
          under the GoGuardian brand, other than the Websites.
        </p>
        <p>
          17.16.2<strong> Pear Deck Offering(s) </strong>means Liminex’s suite
          of products and services, including Professional Services, provided
          under the Pear Deck brand, other than the Websites.
        </p>
        <p>
          17.16.3<strong> Edulastic Offering(s) </strong>means Liminex’s suite
          of products and services, including Professional Services, provided
          under the Edulastic brand, other than the Websites.
        </p>
        <p>
          17.16.4 <strong> Giant Steps Offering(s) </strong> means Liminex’s
          suite of products and services, including Professional Services,
          provided under the Giant Steps brand, other than the Websites.
        </p>
        <p>
          17.16.5 <strong> TutorMe Offering(s) </strong> means Liminex’s suite
          of products and services, including Professional Services, provided
          under the TutorMe brand, other than the Websites.
        </p>
        <p>
          17.17 <strong> Liminex Parties </strong>means Liminex, its Affiliates,
          including Pear Deck, Edulastic, TutorMe, and each of their respective
          licensors, suppliers, officers, directors, employees, shareholders,
          agents and representatives.
        </p>
        <p>
          17.18<strong> Licensed Capacity </strong>means the number of licenses
          to an Offering covered by a Subscription during a Subscription Term
          (e.g., depending on the particular offering and licensing model
          indicated on the Order Form, the number of accounts, authorized
          devices, installations, seats, or tutoring hours).
        </p>
        <p>
          17.19<strong> Managed Device or Account </strong>means any device
          and/or account of a student or user for which School deploys or
          installs an Offering or seeks participation from an Offering for such
          purpose in accordance with the terms of this Agreement.
        </p>
        <p>
          17.20<strong> Order Form </strong>means a written or online ordering
          document, communication, form, statement of work, or other
          documentation that specifies the Offering(s) to be provided and which
          is either executed or submitted by you, and then accepted by a
          Liminex, one of its Affiliates or one of their Authorized Resellers.
          Each Order Form identifies the purchased Offering(s), any applicable
          fees, and, for licensed products and services, the Subscription Term
          and Licensed Capacity, as well as any other terms as agreed to between
          the parties. If an Order Form with School indicates that any School
          Affiliates will be receiving access to the Offering(s) hereunder, each
          of them will be bound by the terms of this Agreement as if they were
          an original party hereto.
        </p>
        <p>
          17.21<strong> Parent/Guardian Information </strong>means any data or
          information provided, submitted, or made available in an Offering
          about a parent or guardian of a student user of a Managed Device or
          Account, including the individual’s association with a particular
          child and his or her contact information (e.g., email address).
        </p>
        <p>
          17.22<strong> Content </strong>means all information (such as data
          files, fonts, written text, computer software, music, audio files,
          image files or other sounds, photographs, videos or other images)
          which you may have access to as part of, or through your use of, any
          Offerings.
        </p>
        <p>
          17.23<strong> Personal Student Information </strong>means information
          provided, submitted, or made available in an Offering about a student
          user of a Managed Device or Account (including, the student’s
          School-managed account information, Activity Information, grade and
          attendance data, and location information to the extent collected)
          that may, alone or in combination with other available information, be
          reasonably used to identify a current or former student enrolled in a
          School, including Educational Records defined herein.
        </p>
        <p>
          17.24<strong> Professional Services </strong>means any supplemental
          technical, training, support, consulting or implementation services
          that Liminex or one of its Affiliates provides to you, as set forth in
          an Order Form. Any purchased Professional Services, whether
          provisioned in connection with or support of another Liminex Offering,
          shall be deemed an Offering hereunder.
        </p>
        <p>
          17.25<strong> School </strong>means the school, school district,
          corporation, organization, college, university, or other legal entity
          identified on an Order Form on whose behalf the individual accepts
          this Agreement and, and any Affiliates of that school, school
          district, corporation, organization or other legal entity have entered
          into Order Form(s) (for so long as they remain Affiliates).
        </p>
        <p>
          17.26<strong> School Account Information </strong>means School Profile
          Information, Authorized School Personnel Information, and Association
          Information.
        </p>
        <p>
          17.27<strong> School Affiliate </strong>means any Affiliate of School
          that has been designated in an Order Form as authorized to access the
          Offerings under this Agreement.
        </p>
        <p>
          17.28<strong> School Log and Cookie Information </strong>means
          analytics, log and event information, such as IP address, Internet
          service provider (ISP), date and time stamp, browser language, browser
          type, amount of time spent on particular portions of the Offerings,
          service diagnostics and technical logging information, device
          information, and/or other general usage data, automatically collected
          by or on behalf of Liminex or an Affiliate.
        </p>
        <p>
          17.29<strong> School Profile Information </strong>means the name,
          email address, and phone number of the individual ordering the
          Offering on School’s behalf, as well as the School’s name, address,
          billing address, of the School or Authorized User, as applicable, the
          number of devices, number of students, and network configuration, and
          to the extent an Account is created for School, the password created
          for such Account.
        </p>
        <p>
          17.30<strong> Subscription </strong>means a time-limited right granted
          to School to access and use one or more licenses to an Offering under
          the terms of this Agreement.
        </p>
        <p>
          17.31<strong> Subscription Term </strong>means the period of time for
          which you subscribe (whether through a standard license or as part of
          a trial or beta license) to a particular Offering, as specified in an
          Order Form. For the avoidance of doubt, “Subscription Term” includes
          the initial Subscription Term agreed to in the Order Form and any
          subsequent renewal Subscription Term under that Order Form.
        </p>
        <p>
          17.32<strong> User Documentation </strong>means the online user
          guides, documentation, and help and training materials we make
          accessible at GoGuardian
          <a
            href="https://help.goguardian.com/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center</strong>
          </a>
          , the Pear Deck
          <a
            href="https://help.peardeck.com/en?__hstc=181186424.a331f9cd231b0501655e39b86932d339.1628617317261.1637174793540.1637256220337.25&amp;__hssc=181186424.1.1637256220337&amp;__hsfp=431394172"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center</strong>
          </a>
          , the Edulastic
          <a
            href="https://edulastic.com/help-center/?__hstc=181186424.a331f9cd231b0501655e39b86932d339.1628617317261.1637174793540.1637256220337.25&amp;__hssc=181186424.1.1637256220337&amp;__hsfp=431394172"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center</strong>
          </a>
          , the Giant Steps
          <a
            href="https://support.giantsteps.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center</strong>
          </a>
          , the TutorMe
          <a
            href="https://help.tutorme.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Help Center</strong>
          </a>
          , the
          <a
            href="https://tutorme.com/policies/code-of-conduct"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Code of Conduct - TutorMe</strong>
          </a>
          , or such other URL we identify from time to time, and any other
          materials we provide as part of the Offerings, all as may be updated
          from time to time.
        </p>
        <p>
          17.33 <strong> Website(s) </strong>means the Liminex or its
          Affiliates’ websites made available online to the public that are not
          the Offerings.
        </p>
      </div>
    </BodyContentWrapper>
  )
}

export default EulaPolicyContent
