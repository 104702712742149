import React from 'react'
import { BodyContentWrapper, StickyHeader } from './Styled'

// This content is generated through https://github.com/mwilliamson/mammoth.js/
const ProductPolicyContent = () => {
  return (
    <BodyContentWrapper data-testid="productPrivacyPolicy">
      <StickyHeader>
        <strong> Privacy Policy for Product Users </strong>
      </StickyHeader>
      <div>
        <p>Last Updated Date: April 24, 2023</p>
        <h2>
          <strong> Introduction </strong>
        </h2>
        <p>
          Welcome! GoGuardian (formally — Liminex, Inc. doing business as
          GoGuardian) and its family of company Affiliates including Pear Deck,
          Inc. (“Pear Deck”), Edulastic (formally - Snapwiz, Inc. doing business
          as Edulastic — referred to throughout as “Edulastic”), Zorro Holdco,
          LLC dba TutorMe (“TutorMe”), and Giant Steps (“Giant Steps”)
          (together, Liminex, Inc. dba GoGuardian, Pear Deck, Edulastic,
          TutorMe, and Giant Steps as “Liminex”, “we”, “us”, “our”) is an
          educational technology company that provides K-12 schools and school
          districts with digital learning tools designed to supercharge the
          learning potential of every student. The privacy of each and every
          user matters deeply to us, and we strive for transparency in all of
          our data privacy practices—especially when it comes to identifying the
          information that we collect, use, share, and disclose, as well as how
          we help keep your information safe. We take our responsibility to
          protect your information very seriously. Please remember that we are
          always here to answer any questions you have, and we look forward to
          working with you for years to come. Capitalized terms used herein that
          are not expressly defined are as defined in our
          <a
            href="https://edulastic.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Product Terms of Service and End User License Agreement
          </a>
          .
        </p>
        <p>
          This Product Privacy Policy (“Product Privacy Policy”) explains how we
          collect, use, share, disclose, and protect information collected from
          and about our School customers that are using our Offering(s) and such
          School’s Authorized School Personnel. Our Offerings include the
          following:
        </p>
        <ul>
          <li>
            GoGuardian Offerings
            <ul>
              <li>
                <a
                  href="https://www.goguardian.com/admin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoGuardian Admin
                </a>
              </li>
              <li>
                <a
                  href="https://www.goguardian.com/beacon/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoGuardian Beacon
                </a>
              </li>
              <li>
                <a
                  href="https://www.goguardian.com/director/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoGuardian Director
                </a>
              </li>
              <li>
                <a
                  href="https://www.goguardian.com/dns/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoGuardian DNS
                </a>
              </li>
              <li>
                <a
                  href="https://www.goguardian.com/fleet/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoGuardian Fleet
                </a>
              </li>
              <li>
                <a
                  href="https://www.goguardian.com/teacher/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoGuardian Teacher
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="https://www.peardeck.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pear Deck Offerings
            </a>
          </li>
          <li>
            <a
              href="https://edulastic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Edulastic Offerings
            </a>
          </li>
          <li>
            <a
              href="https://tutorme.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TutorMe Offerings
            </a>
          </li>
          <li>
            <a
              href="https://www.giantsteps.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Giant Steps Offerings
            </a>
          </li>
        </ul>
        <p>
          This Product Privacy Policy does <em> not </em> apply to:
        </p>
        <ul>
          <li>
            visitors and users of our general audience Website and their
            information (please see our
            <a
              href="https://edulastic.com/privacy-policy-for-website-visitors/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Privacy Policy for Website visitors
            </a>
            (&ldquo;Website Privacy Policy&rdquo;)); or
          </li>
          <li>
            organizations or third parties that we do not own or manage, or to
            individuals that we do not employ or manage. We encourage you to
            review the applicable third party privacy policies and terms of use
            before submitting any information through those sites or services.
          </li>
          <li>
            Data submitted as a job applicant to GoGuardian and its Affiliates.
          </li>
        </ul>
        <p>
          By using our Offerings, you, the school or school district (“School,”
          “you,” “your”), are declaring that you have read and understand this
          Product Privacy Policy. If you are acting on behalf of a School, you
          represent and warrant that you are at least eighteen (18) years old
          and have the authority, the right, and the capacity to legally bind
          the school and have read and understand this Product Privacy Policy.
          Our Combined
          <a
            href="https://edulastic.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Terms of Service and End User License Agreement
          </a>
          (&ldquo;EULA&rdquo;), and as applicable to the extent your School is
          in the European Economic Area (&ldquo;EEA&rdquo;), our
          <a
            href="https://edulastic.com/data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Data Protection Addendum
          </a>
          (&ldquo;DPA&rdquo;), also apply to how we treat your information.
        </p>
        <h3>
          <strong>
            Information Provided Directly to Us through our Offerings
          </strong>
        </h3>
        <p>
          The information that we collect depends on the Offerings and features
          within those Offerings that a School uses. We collect some business
          information directly from a School (e.g., information collected when
          signing up to use our Offerings) or from Schools about their students
          or directly from the School’s students (e.g., browsing information)
          when we act as their service provider. We also collect some
          information automatically (e.g., Offerings usage information).
        </p>
        <h4>
          <strong> School and School Official Information </strong>
        </h4>
        <p>
          We collect the following information that we collectively label as
          “School Information”:
        </p>
        <ul>
          <li>
            <em> School Profile Information: </em> When an official of a School
            (&ldquo;School Official&rdquo;) initiates contact through our
            Website for the Offerings on behalf of his/her School, we collect
            that School Official’s contact information as well as information
            about his/her School relevant to purchasing and setting up our
            Offerings. This information includes the School Official’s name,
            email address, and phone number, as well as the School’s name,
            address, billing address, number of devices, number of students, and
            network configuration. If the School Official creates an account for
            the Offerings, the School Official must also create a password.
          </li>
          <li>
            <em> Authorized School Personnel: </em> In certain Offerings,
            following a School Official’s establishment of a School account, the
            School Official can use the Offerings to add additional School
            personnel such as other School Officials and teachers (together,
            &ldquo;Authorized School Personnel&rdquo;) from their School and
            designate their appropriate permission levels in the Offerings.
            Certain Offerings require the School Official and/or their
            Authorized School Personnel to provide class enrollment information
            (e.g., class name and subject).
          </li>
          <li>
            <em> Support Requests: </em> Authorized School Personnel can submit
            requests about the Offerings to our support center (e.g., our
            Support Center and our Help Center) through both our Offerings
            themselves and from our Website. When Authorized School Personnel
            submit support requests through these support centers, we receive
            these requests and associated contact information.
          </li>
          <li>
            <em> Additional Information About Authorized School Personnel: </em>{' '}
            Depending on the Offerings and features selected by a School, the
            Offerings can collect additional information about Authorized School
            Personnel, including chat messages and video conferencing between
            students and teachers using GoGuardian Teacher (including if
            Personnel participate in a chat function as part of a
            videoconference), GoGuardian-generated unique account identifiers,
            and other relevant unique identifiers (e.g., Google Classroom
            identifiers if School integrates Google Classroom with GoGuardian
            Teacher). This information may change if a School and/or Authorized
            School Personnel utilize new features and/or Offerings.
          </li>
        </ul>
        <p>
          <strong> Personal Student Information for All Offerings </strong>
        </p>
        <p>
          When addressing a specific Offering in this Product Privacy Policy, we
          may refer to the name of the entity and the Personal Student
          Information collected by such entity. Each school in the United States
          must comply with any legal requirements applicable to it, which may
          include, providing appropriate notice and/or obtaining parental
          consent. Schools located in countries outside of the United States
          must also obtain and provide appropriate consent from parents and/or
          guardians of students or as otherwise required by applicable law. We
          only collect Personal Student Information where Schools have provided
          consent required by applicable law, including the Children’s Online
          Privacy Protection Act (“COPPA”). The Personal Student Information
          collected may change if a School utilizes new features or Offerings.
        </p>
        <h4>
          <strong> GoGuardian Personal Student Information </strong>
        </h4>
        <p>
          This section applies to the GoGuardian Offerings only. In providing
          our GoGuardian Offerings to Schools, we collect information that may,
          alone or in combination with other available information, be
          reasonably used to identify a current or former student enrolled in a
          K-12 School (“Personal Student Information”). GoGuardian only collects
          Personal Student Information on school-managed devices and/or accounts
          where a School has already registered with GoGuardian and chosen to
          use GoGuardian Offerings and where such Schools have consented as set
          forth above. The Personal Student Information collected by our
          GoGuardian Offerings on behalf of a School depends on the particular
          GoGuardian Offerings and features selected by a School and the
          particular settings enabled or disabled by the School. Pursuant to
          such selections, the Personal Student Information that we collect
          includes:
        </p>
        <ul>
          <li>
            <em>
              Student’s School-Managed Account Information and Association
              Information:
            </em>{' '}
            GoGuardian’s Offerings collect a students’ Google or Microsoft
            School-managed account information: student’s name, email address,
            Google Profile ID and Google Image URL (for school-managed Google
            accounts), Microsoft Profile ID (for school-managed Microsoft
            accounts), and organizational unit as well as device identifiers
            necessary to associate a student with a certain device and settings.
            Additionally, GoGuardian Offerings may also need to associate a
            student with a particular teacher’s classroom by using a Google
            classroom identifier or other identifier.
          </li>
          <li>
            <em> Activity Information: </em> Depending on a School’s currently
            selected GoGuardian Offerings, features, integrations and settings,
            GoGuardian may collect additional information, from or about
            school-managed accounts or devices and online activity, including
            chats (within GoGuardian Teacher), video conferencing recordings
            (including audio and videos of participating Students and chats
            included in a video conference) (within GoGuardian Teacher), a
            student’s browsing history, IP address, online content, screenshots,
            and key percentage variance ((but not the actual inputs or sequence
            of inputs) to identify patterns like gaming).
          </li>
          <li>
            <em> Grade, Attendance, and Assignment Information: </em> Depending
            on a School’s currently selected GoGuardian Offerings, features,
            integrations, and settings, GoGuardian may also receive grade,
            attendance, and assignment information.
          </li>
          <li>
            <em> Location Information: </em> Using GoGuardian Admin, a School
            can collect the geographic location of devices for the purpose of
            locating and recovering its devices. GoGuardian Offerings can
            collect IP addresses while GoGuardian Offerings are active, no
            precise geographic login locations of mobile phones can be accessed
            or stored by GoGuardian.
          </li>
          <li>
            <em> Integration Information: </em> If your School chooses to
            integrate GoGuardian Offerings with other Integration Offerings
            (e.g., integrate with other School software with GoGuardian
            Offerings), then we will collect the information necessary to
            integrate and perform our Offerings, including unique identifiers
            (e.g., Google classroom identifier to connect a student to his/her
            classroom if your School integrates Google Classroom with GoGuardian
            Teacher) and other information (e.g., Parent/Guardian Information if
            your School integrates GoGuardian Offerings with the Parent/Guardian
            Information from Classlink or Clever).
          </li>
        </ul>
        <p>
          <strong> Pear Deck Personal Student Information </strong>
        </p>
        <p>
          This section applies to the Pear Deck Offerings only. The Personal
          Student Information collected by the Pear Deck Offerings on behalf of
          a School depends on the particular features selected by a School and
          the particular settings enabled or disabled by the School. Pursuant to
          such selections, the Personal Student Information that we collect
          includes:
        </p>
        <ul>
          <li>
            <em>
              Student’s School-Managed Account Information and Association
              Information:{' '}
            </em>
            For students who log into Pear Deck with a School-Managed Account,
            we collect student’s name, email address, the school-managed account
            ID ( for example the Google ID or Microsoft ID), as well as
            identifiers and associated information necessary to associate a
            student with a certain device, account, presentation, teacher,
            and/or school. When a student joins a session with a code in
            anonymous mode, we do not collect student name or email address to
            join such session.
          </li>
          <li>
            <em> Activity Information: </em>We collect additional information
            about the student’s activity within a presentation, including
            engagement with and any student content generated in the session.
            This may include free text, multiple choice answers, drawings, or
            presentation URLs.
          </li>
          <li>
            <em> Device and Usage Information: </em>We collect general device
            and usage information such as IP address, device identifier,
            operating system, browser type, non-precise geographic location
            (e.g. zip code and city), technical information about your device,
            system and app software, and peripherals, and date and time stamps
            associated with login.
          </li>
          <li>
            <em> Integration Information: </em>If your School chooses to
            integrate Pear Deck Offerings with other Integration Offerings
            (e.g., integrate with other School software with Pear Deck), then we
            will collect the information necessary to integrate and perform our
            Offerings, including unique identifiers (e.g., Google classroom
            identifier to connect a student to his/her classroom if your School
            integrates Google Classroom with Pear Deck).
          </li>
        </ul>
        <p>
          <strong> Edulastic Personal Student Information </strong>
        </p>
        <p>
          This section applies to the Edulastic Offerings only. The Personal
          Student Information collected by the Edulastic Offerings on behalf of
          a School depends on the particular features selected by a School and
          the particular settings enabled or disabled by the School. Pursuant to
          such selections, the Personal Student Information that we collect
          includes:
        </p>
        <ul>
          <li>
            <em>
              Student’s School-Managed Account Information and Association
              Information
            </em>
            : The Edulastic Offerings may collect a student’s name, email
            address, student ID, Edulastic user name.
          </li>
          <li>
            <em> Activity Information: </em>We may collect additional
            information, from or about any activity when a user is logged on,
            including audio recordings in an assessment.
          </li>
          <li>
            <em> Demographic Information: </em>We may collect student
            demographic information, including gender, date of birth, English
            language learner status, individualized education plan status, and
            free and reduced lunch status).
          </li>
          <li>
            <em> Assessment Information: </em>We collect student assessment
            responses, student assessment scores, and teacher feedback.
          </li>
          <li>
            <em> Device and Usage Information: </em>We collect general device
            and usage information such as IP address, browser type, time on task
            (the length of time a student spends on a question), browser lock
            (if students try to leave a browser), and user interaction.
          </li>
          <li>
            <em> Integration Information: </em>If your School chooses to
            integrate Edulastic Offerings with other Integration Offerings
            (e.g., integrate with other School software with Edulastic
            Offerings), we will collect the information necessary to facilitate
            such integration, including unique identifiers (e.g., SIS data for
            enterprise integration).
          </li>
          <li>
            including unique identifiers (e.g., SIS data for enterprise
            integration).
          </li>
        </ul>
        <p>
          <strong> TutorMe Personal Student Information </strong>
        </p>
        <p>
          This section applies to the TutorMe Offerings only. The Personal
          Student Information collected by the TutorMe Offerings includes:
        </p>
        <ul>
          <li>
            <em>
              Student’s School-Managed Account Information and Association
              Information:
            </em>{' '}
            TutorMe’s Offerings collect a students’ full name, email address and
            password. Additionally, TutorMe offerings may also need to associate
            a student with a particular teacher’s classroom by using a Google
            classroom identifier or other identifier.
          </li>
          <li>
            <em> Activity Information: </em>TutorMe may collect additional
            information, from or about any activity when a user is logged on
            including, chats, audio and video recording during virtual tutoring
            sessions, free text or drawings on the whiteboard during virtual
            tutoring sessions, and documents submitted to the TutorMe Writing
            Lab.
          </li>
          <li>
            <em> Device and Usage Information: </em>TutorMe collects general
            device and usage information such as IP address, device identifier,
            operating system, browser type, non-precise geographic location,
            technical information about device and date and time stamps
            associated with logins.
          </li>
          <li>
            <em> Integration Information: </em>If you school chooses to
            integrate TutorMe Offerings with other Integration Offerings (e.g.,
            integrate with other School software with TutorMe Offerings), then
            we will collect the information necessary to integrate and perform
            our Offerings, including unique identifiers (e.g., SIS data for
            enterprise integration).
          </li>
        </ul>
        <p>
          <strong> Giant Steps Personal Student Information </strong>
        </p>
        <p>
          This section applies to the Giant Steps Offerings only. The Personal
          Student Information collected by the Giant Steps Offerings on behalf
          of a School depends on the particular features selected by a School
          and the particular settings enabled or disabled by the School.
          Pursuant to such selections, the Personal Student Information that we
          collect includes:
        </p>
        <ul>
          <li>
            <em>
              Student’s School-Managed Account Information and Association
              Information:
            </em>{' '}
            For students who log into Giant Steps with a School-Managed Account,
            we collect student’s name, email address, the school-managed account
            ID (for example the Google ID or Microsoft ID), as well as
            identifiers and associated information necessary to associate a
            student with a certain device, account, session, teacher and/or
            school. When a student joins a session with a code in anonymous
            mode, we do not collect student name or email address to join such
            session.
          </li>
          <li>
            <em> Activity Information: </em>We collect additional information
            about the student’s activity within a session, including engagement
            with and any student content generated in the session. This may
            include free text, multiple choice answers, drawings, or session
            URLs.
          </li>
          <li>
            <em> Device and Usage Information: </em>We collect general device
            and usage information such as IP address, device identifier,
            operating system, browser type, non-precise geographic location
            (e.g. zip code and city), technical information about your device,
            system and app software, and peripherals, and date and time stamps
            associated with login.
          </li>
          <li>
            <em> Integration Information: </em>If your School chooses to
            integrate Giant Steps Offerings with other Integration Offerings
            (e.g., integrate with other School software with Giant Steps), then
            we will collect the information necessary to integrate and perform
            our Offerings, including unique identifiers (e.g., Google classroom
            identifier to connect a student to his/her classroom if your School
            integrates Google Classroom with Giant Steps).
          </li>
        </ul>
        <h3>
          <strong> Parent/Guardian Information </strong>
        </h3>
        <p>
          If a School chooses to utilize one of our Offerings’ guardian feature
          (such as to enable a School to send important automated notifications
          to a parent/guardian about his/her child for a GoGuardian Offering or
          to view assessment results in Edulastic), we will receive certain
          information about a student’s parent(s)/guardian(s). Specifically,
          depending on the guardian feature, we may receive a
          parent’s/guardian’s name, email address, and optionally phone number
          from the School. To learn whether your child’s School is using this
          guardian role or feature or how to update a child’s legal
          parent/guardian contact information, please contact your child’s
          School and follow the instructions below in the section below titled
          “Requests from Parents, Legal Guardians, Eligible Students, and
          Authorized School Personnel”.
        </p>
        <p>
          If you are a parent or guardian and utilize a guardian feature
          (including the Parent App in GoGuardian and guardian feature in
          Edulastic), we collect: 1) your email address to confirm your
          authorization from the School to use the app and to determine the
          student associated with you and, once verified, enable the app; and 2)
          automatically collected information about your app activity set forth
          below.
        </p>
        <h3>
          <strong>
            Information We Automatically Collect through Our Offerings
          </strong>
        </h3>
        <p>
          Our Offerings automatically collect the following additional
          information that we label collectively as “School Log and Cookie
          Information”:
        </p>
        <ul>
          <li>
            <em> Analytics Information and Event Information: </em> Our
            Offerings automatically collect information through analytics
            services providers, including IP address, Internet service provider
            (ISP), date and time stamp, browser language, browser type, amount
            of time spent on particular portions of our Offerings, and/or other
            general usage data. Similar to other software, we also collect event
            information regarding how users interact with our Offerings. For
            example, we collect information about how much time users spend on
            certain features of our Offerings.
          </li>
          <li>
            <em> Log Information: </em>We also collect log information such as
            service diagnostics and technical logging information, which may
            include IP address, login times to our Offerings, usage times of our
            Offerings, browser type, and browser configuration.
          </li>
          <li>
            <em> Device Information: </em> We collect information about the
            devices that log into our Offerings, including type of device,
            device settings, and operating system.
          </li>
          <li>
            <em> Cookies: </em> We use Cookies in our Offerings for the purposes
            described in our Cookies Notice.
          </li>
        </ul>
        <h3>
          <strong>
            How We Use Information Collected Through Our Offerings
          </strong>
        </h3>
        <p>
          We use School Information and School Log and Cookie Information to:
        </p>
        <ul>
          <li>set up and provide our Offerings;</li>
          <li>analyze and improve our Offerings;</li>
          <li>
            respond to a School’s and/or Authorized School Personnel’s requests
            and to provide related support;
          </li>
          <li>administer and troubleshoot our Offerings;</li>
          <li>
            send information to a School and Authorized School Personnel about
            how to use our Offerings and other important updates;
          </li>
          <li>
            notify a School and Authorized School Officials about new features
            or Offerings that may be of interest to them, and, similarly notify
            other Authorized School Personnel of new features or Products that
            may be of interest to them when residents of the United States or
            otherwise as permitted by law;
          </li>
          <li>
            help prevent fraud and to enforce the legal terms that govern our
            Offerings;
          </li>
          <li>
            to comply with applicable law and protect our and others’ rights,
            safety and property;
          </li>
          <li>follow further instructions of a School; and</li>
          <li>
            to determine general, non-identifying demographics like location
            (down to the city level), age range (e.g. 25-35), and equipment
            profiles (e.g. Chromebook with medium-size screen.
          </li>
        </ul>
        <p>
          We do not sell School Information and School Log and Cookie
          Information or share it for cross context behavioral advertising (and
          have not done so in the past 12 months).
        </p>
        <p>
          In accordance with our contractual obligations, we use Personal
          Student Information and Parent/Guardian Information to:
        </p>
        <ul>
          <li>
            Provide, improve and analyze our Offerings and for legitimate
            educational purposes of the School;
          </li>
          <li>
            to send parents and guardians alerts and notices in the Parent App
            or otherwise provide information to parents and guardians consistent
            with the guardian feature;
          </li>
          <li>enforce the legal terms that govern our Offerings;</li>
          <li>
            comply with applicable law and protect our and others’ rights,
            safety and property; and
          </li>
          <li>follow further instructions of a School.</li>
        </ul>
        <p>
          At all times, Personal Student Information is the property of and
          under the control of a School, or as required by applicable law,
          Personal Student Information is the property of and under the control
          of the applicable eligible student, parent, or guardian.
        </p>
        <p>
          We do not sell Personal Student Information. We do not use Personal
          Student Information to target advertisements or market to students or
          anyone else, to amass a profile about a K-12 educational student for a
          non-educational purpose, or for any purposes prohibited by the Family
          Educational and Privacy Rights Act (20 U.S.C. § 1232g; 34 CFR Part
          99.3) (&ldquo;FERPA&rdquo;), California Business &amp; Professions
          Code section 22584 (“SOPIPA”), and California Education Code section
          49073.1. We will not use Personal Student Information for any purpose
          other than those required or specifically set forth in this Product
          Privacy Policy, our
          <a
            href="https://edulastic.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            EULA
          </a>
          ,
          <a
            href="https://edulastic.com/data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            DPA
          </a>
          , or any other agreement between a School and Liminex.
        </p>
        <p>
          We will also use your Personal Student Information in accordance with
          applicable student privacy laws, including, as applicable,
          Connecticut’s &ldquo;Act Concerning Student Privacy&rdquo;.
        </p>
        <p>
          If you are a Product User in the EEA or the United Kingdom
          (&ldquo;UK&rdquo;), please see the “Notice to EEA and Other Non-US
          Residents” section for more information about how we use information
          and the legal bases for collecting information.
        </p>
        <h3>
          <strong>
            How We Disclose Information Collected Through Our Offerings
          </strong>
        </h3>
        <p>We may disclose information in the following ways:</p>
        <ul>
          <li>
            <em> Affiliates: </em> We provide access to or share your
            information with current or future family of GoGuardian companies
            including our Affiliates, such as Pear Deck, Giant Steps, and
            Edulastic.
          </li>
          <li>
            <em> Authorized School Personnel Access: </em> Each School
            determines which Authorized School Personnel have access to the
            School’s account with Offerings and their permission levels.
          </li>
          <li>
            <em> Third Parties Supporting Our Offerings: </em> We may share
            information with our service providers that assist us in performing
            business-related functions that support our Offerings. Information
            about third party service providers utilized by GoGuardian Offerings
            is available
            <a
              href="https://uploads-ssl.webflow.com/60db82d1be38ad3341c2ff23/640b5b0687a187ddc77fd06f_GoGuardian%20Third%20Party%20Service%20Partners%20-%2012-13-2022.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              here
            </a>
            . For example, we use hosting services and customer service tools to
            help support our Offerings. When we employ another company to
            perform a function of this nature, we provide them with the
            information that they need to perform their specific function.
          </li>
          <li>
            <em> With School Permission or at School Direction: </em> We may
            disclose information, including Personal Student Information, to
            third parties pursuant to a School’s instruction or with a School’s
            permission. For example, if a School chooses to integrate our
            Offerings with another third party company’s products or services,
            then we will share information as instructed. With your permission,
            we may also share or disclose your name and other content through a
            testimonial video or quotation.
          </li>
          <li>
            <em> Protection of Liminex and Others: </em> We may disclose certain
            of the information we collect (set forth above) if we believe in
            good faith that doing so is necessary or appropriate to (i) protect
            or defend the rights, safety, or property of Liminex or third
            parties, including to defend or enforce our Product Privacy Policy,
            EULA, or any other contractual arrangement; or (ii) to respond to
            claims that content violates the rights of third parties.
          </li>
          <li>
            <em> Legal Requirements: </em> We may disclose certain information
            if we believe in good faith that doing so is necessary or
            appropriate to comply with any law enforcement, legal, or regulatory
            process, such as to respond to a warrant, subpoena, court order, or
            other applicable laws and regulations.
          </li>
          <li>
            <em> Business Transfer: </em> We may disclose certain information we
            collect (set forth above), in connection with or during negotiations
            of any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company. If such
            transfer is subject to additional mandatory restrictions under
            applicable laws, we will comply with such restrictions. The
            successor entity will be subject to all applicable federal and state
            laws, including student privacy laws. In connection with such a
            transaction, we will also work to ensure that the successor entity
            has a commitment to student privacy.
          </li>
          <li>
            <em> De-Identified Information: </em> We may use, share, or disclose
            De-Identified information (as that term is defined in our EULA) for
            various purposes such as product development, research, and
            marketing, in compliance with relevant laws. For example, we may use
            aggregate, anonymous data to publish an e-Book on Schools’ most
            utilized online educational resources.
          </li>
        </ul>
        <p>
          If you are a School or other Authorized School Personnel in the EEA or
          the UK, please see our Notice to EEA and Other Non-US Residents below.
        </p>
        <h3>
          <strong> How Long We Keep Information </strong>
        </h3>
        <p>We retain Personal Student Information as directed by the School:</p>
        <ul>
          <li>
            <em>
              School’s Request for Deletion or Transfer of Personal Student
              Information:{' '}
            </em>
            We act upon a School’s documented instructions to delete Personal
            Student Information during and after all School licenses to the
            Offerings have expired, unless we are required to retain such
            information to comply with our legal obligations with law
            enforcement, resolve disputes, or enforce our agreements. Upon
            instruction to delete Personal Student Information, we will take
            reasonable efforts to delete and/or de-identify such information in
            a commercially reasonable amount of time under applicable laws. At
            the termination of all of the School’s licenses with us, we and the
            School may agree to transfer the School’s Personal Student
            Information back to the School. In that case, similar to deletion
            requests, we will act upon a School’s documented instruction to
            transfer the School’s Personal Student Information to the School and
            take reasonable efforts to transfer such information in a
            commercially reasonable amount of time under applicable laws, unless
            we are required to retain such information to comply with our legal
            obligations, or with law enforcement, resolve disputes, or enforce
            our agreements. For Schools in the EEA, Personal Student Information
            will be deleted and transferred/ported according to the terms of the
            DPA or other agreement between the School and us.
          </li>
          <li>
            <em> Video Conferencing Recordings: </em>If a School enables the
            video conferencing feature in GoGuardian Teacher, audio and video
            recordings will be stored by GoGuardian for ninety (90) days. If a
            School enables the video conferencing feature in TutorMe, audio and
            video recordings will be stored by TutorMe for ninety (90) days
            after termination of its agreement with the customer.
          </li>
          <li>
            <em> Deactivated Accounts: </em>We may consider a School’s account
            to be terminated 365 days after all of a School’s licenses expire
            without use, reactivation, or renewal by the School. At that time,
            if a School has not requested deletion of Personal Student
            Information, we will automatically de-identify and/or delete the
            School’s Personal Student Information within a commercially
            reasonable period of time as long as that information is not
            necessary to comply with our legal obligations, resolve disputes, or
            enforce our agreements. All Personal Student Information retained
            will remain subject to our Product Privacy Policy that was in effect
            at that time — even after the deactivation of an account or
            termination of a contract.
          </li>
        </ul>
        <p>
          We generally retain information other than Personal Student
          Information for as long as it may be relevant to the purposes above or
          as required or permitted by law. We consider the nature and
          sensitivity of the information, the purposes for which we process the
          information, applicable legal requirements, and our legitimate
          interests in determining how long to retain information. The purposes
          for which we use information may dictate different retention periods
          for the same types of information. To dispose of any personal
          information, we may anonymize it, delete it or take other appropriate
          de-identifying steps. Please note that information may persist in
          copies made for backup and business continuity purposes for an
          additional period of time.
        </p>
        <h3>
          <strong>
            Your Rights and Choices about Information Collected through Our
            Offerings
          </strong>
        </h3>
        <p>
          You have certain rights and choices with respect to your information
          as further described in this section.
        </p>
        <p>
          Your choices about School Profile Information and School Log and
          Cookie Information:
        </p>
        <ul>
          <li>
            <em> Promotional Communications: </em> You can decline promotional
            communications at the point information is requested for such
            purpose (e.g., by checking the relevant box) or, by following the
            unsubscribe instructions on communications sent to you. You can also
            contact us as described in the “How to Contact Us” section below.
          </li>
          <li>
            <em> Cookies and Analytics: </em> You can opt out from certain
            cookie-related and analytics processing by following the
            instructions in our Cookie Notice below.
          </li>
        </ul>
        <p>
          Privacy rights related to School Profile Information and School Log
          and Cookie Information
        </p>
        <p>
          : Residents of certain jurisdictions have rights with respect to
          personal information, but, except as set forth below, these rights do
          not apply to business to business information (e.g., business contact,
          contract and business communications). In addition, these rights do
          not apply to personal information collected by Liminex when Liminex is
          acting as a service provider of a School (though we assist Schools in
          fulfilling requests that they receive). You can read more about any
          such applicable rights at our
          <a
            href="https://edulastic.com/privacy-policy-for-website-visitors/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Privacy Policy for Website visitors
          </a>
          . If you are acting on behalf of your School and would like to submit
          a data request, please follow the instructions described in the
          Section titled “School’s Request for Deletion or Transfer of Personal
          Student Information.” Similarly, if you are a parent, guardian,
          eligible student, or Authorized Personnel and would like to submit a
          data request to us, please follow the instructions described in the
          Section titled “Requests from Parents, Legal Guardians, Eligible
          Students, and Authorized School Personnel.”
        </p>
        <p>
          With respect to School Profile Information and School Log and Cookie
          Information, residents of the EEA, UK, and California may request that
          we:
        </p>
        <ul>
          <li>
            (1) provide access to and/or a copy of such information; (2) prevent
            the processing of that individual’s information for direct-marketing
            purposes (including any direct marketing processing based on
            profiling); (3) update an individual’s information which is out of
            date or incorrect; (4) delete certain information that we are
            holding about an individual; and (5) restrict the way that we
            process and disclose certain of the individual’s information. We
            will consider all requests and provide our response within the time
            period stated by applicable law. Please note, however, that certain
            information may be exempt from such requests in some circumstances,
            which may include if we need to continue processing an individual’s
            information for our legitimate interests or to comply with a legal
            obligation. We may request that an individual and/or School provide
            us with information necessary to confirm the individual’s identity
            before responding to your request. To exercise any of these rights,
            please contact us at privacy@goguardian.com. Authorized agents for
            California residents may submit requests at:{' '}
            <a
              href="https://www.goguardian.com/ccpa-requests"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.goguardian.com/ccpa-requests
            </a>
            . In addition to those rights, you may have the right to lodge a
            complaint with the relevant supervisory authority. However, we
            encourage the individual to contact us first, and we will do our
            very best to resolve the individual’s concern.
          </li>
          <li>
            In addition, California residents may annually request a list of the
            third parties to which a company discloses “personal information”
            for such third parties’ marketing purposes and the types of
            information disclosed. However, we do not disclose personal
            information to third parties for such third parties’ direct
            marketing purposes.
          </li>
        </ul>
        <p>
          Your choices about School Information (Except School Profile
          Information), Personal Student Information, and Parent/Guardian
          Information:
        </p>
        <ul>
          <li>
            <em> Cooperation with Schools. </em> We will assist the School in
            facilitating requests relating to Authorized School Personnel
            Information and Personal Student Information pursuant to the
            School’s instruction as explained further in the “How Long We Keep
            Information” or in the “Your Legal Rights Under Local Law” sections
            above.
          </li>
          <li>
            <em>
              Requests from Parents, Legal Guardians, Eligible Students, and
              Authorized School Personnel:
            </em>
            <ul>
              <li>
                <em> Review, Correction, and Deletion Requests. </em>
                <ul>
                  <li>
                    If a parent, legal guardian, eligible student, or Authorized
                    School Personnel wishes to request a review, correction,
                    deletion of Personal Student Information or School
                    Information, he/she should contact the appropriate School
                    Official of his/her or his/her child’s School. We will
                    cooperate with the School to fulfill requests pursuant to
                    the School’s verification and instruction within a
                    commercially reasonable amount of time and in compliance
                    with relevant law.
                  </li>
                  <li>
                    Parent App: If you are a user of the Parent App, you can
                    review information about the choices you may have with
                    respect to the personal information you provide through the
                    Parent App in the “
                    <a
                      href="https://edulastic.com/privacy-policy-for-website-visitors/#heading1_7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Your Choices
                    </a>
                    ” section of our
                    <a
                      href="https://edulastic.com/privacy-policy-for-website-visitors/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Privacy Policy for Website Visitors
                    </a>
                    .
                  </li>
                  <li>
                    If you are Authorized School Personnel, please note that we
                    may be required to maintain certain School Information if
                    directed to do so by your School Official on behalf of your
                    School employer or as needed by us to enforce our legal
                    rights.
                  </li>
                </ul>
              </li>
              <li>
                <em>Transfer Requests. </em> Our Offerings allow Authorized
                School Personnel to download information on behalf of a parent,
                legal guardian, eligible student, or School Personnel. If our
                Offerings do not cover the scope of the transfer request, we
                will, following documented instructions from the School, honor
                the requests from the parent, legal guardian, eligible student,
                or Authorized School Personnel for the transfer of Personal
                Student Information or personal data of the Authorized School
                Personnel by utilizing the same guidelines as described above in
                “Review, Correction, and Deletion Requests” in compliance with
                and as required by relevant law.
              </li>
              <li>
                <em>Transfer Requests for Student-Generated Content. </em>
                California law requires website operators to transfer
                student-generated content to the student’s personal account when
                requested by an eligible student, parent, or guardian. The
                Offerings are not used as the primary platform for students to
                submit their own content. To the extent that students in
                California, or in other states with similar transfer laws,
                submit their content on the Offerings, the appropriate parent,
                guardian, or eligible student may submit a request to{' '}
                <a href="mailto:privacy@goguardian.com">
                  privacy@goguardian.com
                </a>{' '}
                to transfer this information to him/her. Following verification
                of the requestor’s authorization to receive the information
                (which may require verification by the requestor’s School), we
                will provide the student-generated content that we have in a
                commercially reasonable amount of time in either a CSV or other
                mutually-agreeable format. Please note that we cannot guarantee
                retention of student-generated content.
              </li>
            </ul>
          </li>
        </ul>
        <h3>
          <strong> How We Protect Your Information </strong>
        </h3>
        <p>
          We take the security and privacy of your School’s data very seriously.
          For these reasons, we have implemented various technical,
          administrative, and physical safeguards to protect your information,
          including specific training of our personnel authorized to access
          Personal Student Information and other information. These safeguards
          vary depending on the sensitivity of the information at issue.
        </p>
        <p>
          We are committed to preventing unauthorized access to our systems and
          data, and will investigate any possible occurrence. In the event of a
          breach of Personal Student Information, we will comply with all
          relevant breach laws to assist the School or provide notification as
          required to you, a School, affected parents, legal guardians, eligible
          students, and regulators.
        </p>
        <p>
          <strong> Additional Information for California Residents </strong>
        </p>
        <p>
          If you are a California resident, California law requires us to
          provide you with some additional information. We collect the following
          categories of personal information: identifiers (such as name and
          email address); internet or other network information (how users
          interact with our Products); and other information that identifies or
          can be reasonably associated with you (such as your correspondence
          with us). We also collect information at the direction of Schools
          including education information, location information (about devices),
          and audio and visual information.
        </p>
        <p>We use such information to</p>
        <ul>
          <li>set up and provide our Offerings;</li>
          <li>analyze and improve our Offerings;</li>
          <li>
            respond to a School’s and/or Authorized School Personnel’s requests
            and to provide related support;
          </li>
          <li>administer and troubleshoot our Offerings;</li>
          <li>
            send information to a School and Authorized School Personnel about
            how to use our Offerings and other important updates;
          </li>
          <li>
            notify a School and Authorized School Officials about new features
            or Offerings that may be of interest to them, and, similarly notify
            other Authorized School Personnel of new features or Products that
            may be of interest to them when residents of the United States or
            otherwise as permitted by law;
          </li>
          <li>
            help prevent fraud and to enforce the legal terms that govern our
            Offerings;
          </li>
          <li>
            to comply with applicable law and protect our and others’ rights,
            safety and property;
          </li>
          <li>follow further instructions of a School</li>
        </ul>
        <h2>
          We retain such information as long as it is necessary to fulfill the
          purposes for which we collect it, unless a longer retention is
          required or permitted by law. We consider the nature and sensitivity
          of the information, the purposes for which we process the information,
          applicable legal requirements, and our legitimate interests in
          determining how long to retain information.
        </h2>
        <h2>
          <strong> NOTICE TO E.E.A. AND OTHER NON-U.S. RESIDENTS </strong>
        </h2>
        <p>
          European Union (“EU”) data protection laws make a distinction between
          organizations that process personal data for their own purposes (known
          as “data controllers”) and organizations that process personal data on
          behalf of other organizations (known as “data processors”). Depending
          on the particular circumstance, we and your School may act either as a
          data controller or a data processor of your personal data. The laws in
          some jurisdictions like the EU also require data controllers to share
          the legal ground that they rely upon to use or disclose personal
          information.
        </p>
        <h3>
          <strong> Liminex as a Data Controller vs. Data Processor </strong>
        </h3>
        <p>
          Liminex is considered the “processor” of School Information (except
          for School Profile Information), Personal Student Information and
          Parent/Guardian Information. We collect, use, share and disclose this
          information as a processor on behalf of and at the instruction of the
          School controller, pursuant to
          <a
            href="https://edulastic.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            our EULA
          </a>{' '}
          and, if applicable,
          <a
            href="https://edulastic.com/data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            DPA
          </a>
          . If you have a question or complaint about how your personal data is
          handled, please contact your School.
        </p>
        <p>
          We are considered the “controller” of School Profile Information and
          School Log and Cookie Information. We as the data controller collects,
          uses, shares, and discloses this information as described above in
          this Product Privacy Policy. We retain the personal data contained in
          this information for as long as necessary to provide you with
          Offerings, or for other important purposes such as complying with
          legal obligations, resolving disputes, and enforcing our agreements.
        </p>
        <p>
          Our legal grounds for using School Profile Information and School Log
          and Cookie Information are as follows:
        </p>
        <ul>
          <li>
            <em> Contractual Commitments: </em>We use, share, and disclose
            information to honor our contractual commitments to a School.
          </li>
          <li>
            <em> Legal Compliance: </em>We need to use, share, and disclose
            information in certain ways to comply with our legal obligations.
          </li>
          <li>
            <em> With Your Consent: </em>Where required by law, and in some
            other cases, we use, share, or disclose information on the basis of
            consent.
          </li>
          <li>
            <em> Legitimate Interests: </em>In many cases, we use, share, or
            disclose information on the ground that it furthers our legitimate
            business interests in ways that are not overridden by the interests
            or fundamental rights and freedoms of the affected individuals, such
            as customer service, analyzing and improving our business,
            promotional activities, providing security for our Offerings,
            preventing fraud, and managing legal issues.
          </li>
        </ul>
        <h3>
          <strong> Information Transfer To The United States </strong>
        </h3>
        <p>
          The Offerings are operated in the United States. If you are located
          outside of the United States, please be aware that any information
          that you provide to us will be transferred to and/or accessed within
          the United States. The recipients of information described in the
          relevant “How We Share Information” sections above may be located in
          the United States. This information may be subject to United States
          law, including laws that may require disclosure of personal
          information to government authorities. Schools may export Personal
          Data to jurisdictions outside the European Economic Area on the basis
          of legal mechanisms approved by the European Commission and other
          relevant authorities for cross-border data transfers, such as Standard
          Contractual Clauses.
        </p>
        <ul>
          <li>
            <em>
              GoGuardian - European Union-United States and Swiss-United States
              Privacy Shield:{' '}
            </em>
            GoGuardian has certified to the EU-United States and Swiss-United
            States Privacy Shield Frameworks as set forth by the U.S. Department
            of Commerce regarding the collection, use, and retention of
            “personal data” (as defined under the Privacy Shield principles)
            from applicable European countries. We recognize that the Court of
            Justice of the European Union ruled in July 2020 that a
            certification under the EU-U.S. Privacy Shield Framework no longer
            can serve as the basis by which entities subject to the GDPR export
            Personal Data to jurisdictions outside the European Economic Area.
            GoGuardian will continue to honor its obligation to comply with the
            Privacy Shield Principles with respect to data that was transferred
            pursuant to the Privacy Shield Framework. For such data, GoGuardian
            has certified that we adhere to the Privacy Shield Principles of
            notice, choice, purpose limitation, onward transfer, security, data
            integrity, access, recourse, liability, and enforcement for such
            personal data. To learn more about the Privacy Shield Framework,
            visit the Department of Commerce’s Privacy Shield list by clicking{' '}
            <a
              href="http://www.privacyshield.gov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . As required under the principles, when GoGuardian receives
            personal data under the Privacy Shield and then transfers it to a
            third-party service provider acting as an agent on GoGuardian’s
            behalf, we have certain liability under the Privacy Shield if both
            (i) the agent processes the information in a manner inconsistent
            with the Privacy Shield; and (ii) GoGuardian is responsible for the
            event giving rise to the damage.
          </li>
          <li>
            <em>
              Pear Deck and Edulastic - European Union-United States and
              Swiss-United States Privacy Shield:{' '}
            </em>
            Pear Deck and Edulastic have applied for and are pending
            certification with the EU-United States and Swiss-United States
            Privacy Shield as set forth by the U.S. Department of Commerce
            regarding the collection, use, and retention of “personal data” (as
            defined under the Privacy Shield principles) from applicable
            European countries. Pear Deck and Edulastic have certified in our
            applications that we adhere to the Privacy Shield Principles of
            notice, choice, purpose limitation, onward transfer, security, data
            integrity, access, recourse, liability, and enforcement for such
            personal data. To learn more about the Privacy Shield Framework,
            visit the Department of Commerce’s Privacy Shield list by clicking{' '}
            <a
              href="https://www.privacyshield.gov/welcome"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . As required under the principles, when we receive personal data
            under the Privacy Shield and then transfers it to a third-party
            service provider acting as an agent on our behalf, we have certain
            liability under the Privacy Shield if both (i) the agent processes
            the information in a manner inconsistent with the Privacy Shield;
            and (ii) Pear Deck or Edulastic, as applicable, is responsible for
            the event giving rise to the damage.
          </li>
          <li>
            <em> Choices of International Users: </em>Some international users
            (including those whose information we collect under the Privacy
            Shield) of Offerings have certain legal rights to access certain
            information that we hold about them and to request its deletion.
            Please see the “Choices about Information Collected through
            GoGuardian Offerings” section above for more information.
          </li>
        </ul>
        <h3>
          <strong> How To Contact Us and Dispute Resolution </strong>
        </h3>
        <p>
          <em> How to Contact Us for Non-U.S. Residents: </em> Individuals
          located outside the United States who have questions or concerns
          regarding our Product Privacy Policy or practices may direct a
          complaint in writing to Chief Privacy Officer, Liminex, Inc., 2030
          East Maple Avenue, Suite 100, El Segundo, California 90245. Tel:
          888-310-0410 Email: privacy@goguardian.com. Within a reasonable time
          of receiving the written complaint, we will review and address the
          complaint, as appropriate, and inform the individual of the results of
          such review and any steps taken to address the complaint.
        </p>
        <p>
          <em> Dispute Resolution for EU and Swiss Residents: </em> For
          GoGuardian and for Pear Deck and Edulastic once participation in the
          Privacy Shield framework is effective for each of Pear Deck and
          Edulastic, if you are a resident of the EU or Switzerland and are
          dissatisfied with the manner in which we have addressed your concerns,
          you may seek further assistance, at no cost to you, from our
          designated Privacy Shield independent recourse mechanism, the JAMS
          Privacy Shield Program, which you can learn more about by visiting
          <a
            href="https://www.jamsadr.com/eu-us-privacy-shield"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            https://www.jamsadr.com/eu-us-privacy-shield
          </a>
          . Residents of the EU or Switzerland may, in certain circumstances,
          elect to arbitrate unresolved complaints by invoking binding
          arbitration pursuant to the Privacy Shield’s Recourse, Enforcement and
          Liability Principle and Annex I of the Privacy Shield but prior to
          initiating such arbitration, you must: (1) afford us the opportunity
          to resolve the issue; (2) seek assistance via our recourse mechanism
          above; and (3) contact the U.S. Department of Commerce (either
          directly or through a European Data Protection Authority) and afford
          the Department of Commerce time to attempt to resolve the issue. Each
          party shall be responsible for its own attorney’s fees. Please be
          advised that, pursuant to the Privacy Shield, the arbitrator(s) may
          only impose individual-specific, non-monetary, equitable relief
          necessary to remedy any violation of the Privacy Shield Principles
          with respect to the individual. We are subject to the investigatory
          and enforcement powers of the U.S. Federal Trade Commission (FTC).
        </p>
        <p>
          <em> For Australian residents: </em> If you are located in Australia,
          and are dissatisfied with the way we have handled a privacy issue, you
          may contact the Office of the Australian Information Commissioner for
          more information
          <a
            href="https://www.oaic.gov.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            www.oaic.gov.au
          </a>
          .
        </p>
        <p>
          <em> For Singapore residents: </em> If you are located in Singapore,
          and are dissatisfied with the way we have handled a privacy issue, you
          may contact the Personal Data Protection Commission for more
          information
          <a
            href="https://www.pdpc.gov.sg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            www.pdpc.gov.sg
          </a>
          .
        </p>
        <h2>
          <strong> Cookies Notice </strong>
        </h2>
        <p>
          <em> What are cookies? </em>
        </p>
        <p>
          Cookies are small text files stored on your computer that allow us to
          understand usage of Offerings. Cookies include cookies, Internet
          server logs, tags, Software Development Kit/SDKs, tracking pixels, and
          other similar tracking technologies. A number of Cookies that we use
          last only for the duration of your session, expiring when your session
          ends (the so-called “session cookies”). Other Cookies are used to help
          our systems recognize you if you return to Offerings and will thus be
          retained longer (the so-called “persistent cookies”). Some of the
          cookies used on our Offerings are set by us, and some are set by third
          parties that are delivering services on our behalf.
        </p>
        <p>
          <em>
            What types of Cookies do we use and what data do they collect?
          </em>
        </p>
        <p>
          We use Cookies in the Offerings that automatically collect information
          to:
        </p>
        <ul>
          <li>
            understand usage of the Offerings and to improve these Offerings;
          </li>
          <li>authenticate your account; and</li>
          <li>remember your settings and account information.</li>
        </ul>
        <p>
          We do <em> not </em> use any advertising Cookies in our Offerings and
          we do not place advertisements in the Offerings.
        </p>
        <p>
          <em> What are my choices about Cookies? </em>
        </p>
        <p>
          You can learn more about how Cookies work and how to turn them off in
          your particular browsers. Please remember that if you replace, change
          or upgrade your browser, or delete your Cookies, you may need to use
          these opt-out tools again.
        </p>
        <p>
          For information about Cookies used by the developers of these
          browsers, please visit:
        </p>
        <ul>
          <li>
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en-GB"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/kb/260971"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internet Explorer 9
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/kb/PH5042?locale=en_US"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari (Desktop)
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/en-us/HT201265"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari (Mobile)
            </a>
          </li>
          <li>
            <a
              href="http://support.google.com/ics/nexus/bin/answer.py?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Android Browser
            </a>
          </li>
        </ul>
        <p>
          For controls specific to advertising and analytics services offered by
          Google, click{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">here</a> from each
          of your browsers.
        </p>
        <p>
          Note About Do Not Track: Do Not Track (“DNT”): is a privacy preference
          that users can set in certain web browsers to inform websites and
          services that they do not want certain information about their webpage
          visits collected over time and across websites or online services.
          Some browsers do not recognize DNT settings. At this time, we do not
          recognize or respond to browser-initiated DNT signals because we do
          not track you across websites.
        </p>
        <h3>
          <strong> Changes to this Privacy Policy </strong>
        </h3>
        <p>
          We reserve the right to make changes to this Product Privacy Policy
          such as to reflect changes in the law, our data collection and use
          practices, and the features of the Offerings. At the top of our
          Product Privacy Policy, we will indicate the date of the most recent
          update to this Product Privacy Policy. We will notify your School of
          material changes to the Product Privacy Policy on our Website, and
          make additional efforts to notify your School of material changes that
          impact the treatment of data collected via the Offerings and/or via
          email. We will also obtain your School’s consent before any material
          changes to our Product Privacy Policy apply to your School where
          required by applicable law.
        </p>
        <h3>
          <strong> How to Contact Us </strong>
        </h3>
        <p>
          If you have questions or concerns, the best way to reach us is by
          emailing{' '}
          <a href="mailto:privacy@goguardian.com">privacy@goguardian.com</a>.
          You can also reach us by calling 888-310-0410 or by writing to
          Liminex, Inc. dba GoGuardian, Attn: Chief Privacy Officer, 2030 East
          Maple Avenue, Suite 100, El Segundo, California 90245.
        </p>
        <p>
          Additionally, please feel free to consult additional resources such as
          the ones listed below.
        </p>
        <p>Student Privacy Resource List:</p>
        <ul>
          <li>
            <a
              href="https://studentprivacypledge.org/privacy-pledge/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Student Privacy Pledge
            </a>
          </li>
          <li>
            <a
              href="https://studentprivacycompass.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Student Privacy Compass
            </a>
          </li>
          <li>
            <a
              href="https://studentprivacy.ed.gov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Department of Education’s Privacy Technology Assistance Center
              (“PTAC”)
            </a>
          </li>
          <li>
            <a
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Federal Trade Commission’s Complying with COPPA: Frequently
              Asked Questions
            </a>
          </li>
        </ul>
      </div>
    </BodyContentWrapper>
  )
}

export default ProductPolicyContent
