import React from 'react'
import { BodyContentWrapper, StickyHeader } from './Styled'

const EeaPolicyContent = () => {
  return (
    <BodyContentWrapper data-testid="eeaPolicyContent">
      <StickyHeader>
        <strong>Edulastic Data Processing Addendum</strong>
      </StickyHeader>
      <div>
        <h3>Introduction</h3>
        <p>
          This Data Processing Addendum (the ”DPA” or ”Addendum”) forms part of
          the Liminex Products Terms of Service and End User License Agreement
          (available at https://edulastic.com/terms-of-service/) (”EULA”)
          between your Organization (or the ”School”) if the School is located
          in the European Union (”EU”), European Economic Area (”EEA”),
          Switzerland, or the United Kingdom, and Liminex, Inc. doing business
          as GoGuardian (”GoGuardian”). For purposes of this DPA, the School in
          this DPA shall be ascribed the same meaning as your Organization in
          the EULA.
        </p>
        <p>
          In the event of any conflict between this DPA and the EULA with regard
          to the processing of Personal Data, this DPA will control to the
          extent of the conflict. All capitalized terms used but not defined in
          this DPA shall have the meaning ascribed to them in the EULA. For
          avoidance of doubt, this DPA shall not apply to Organizations, Schools
          or our customers located outside of the EEA or Switzerland.
        </p>
        <p>
          In the course of providing our Products under the EULA, we may Process
          certain Personal Data (as such term is defined below) on behalf of the
          School, and where we Processes Personal Data on behalf of the School,
          the parties agree to comply with the terms and conditions in this DPA
          in connection with such Personal Data.
        </p>

        <h3 id="heading1">I. DEFINITIONS</h3>
        <ol type="A">
          <li>
            ”<strong>Controller</strong>” means the entity which determines the
            purposes and means of the Processing of Personal Data.
          </li>
          <li>
            ”<strong>Data Protection Laws</strong>” means all laws and
            regulations, including laws and binding regulations of the EU, the
            EEA and their Member States (including the GDPR) and Switzerland,
            applicable to the Processing of Personal Data under the EULA.
          </li>
          <li>
            ”<strong>Data Subject</strong>” means the identified or identifiable
            person to whom Personal Data relates.
          </li>
          <li>
            ”<strong>GDPR</strong>” means the Regulation (EU) 2016/679 of the
            European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation).
          </li>
          <li>
            ”<strong>Notification Email Address</strong>” means the email
            address of the designated school official for the School.
          </li>
          <li>
            ”<strong>Personal Data</strong>” means any information that relates
            to an identified or identifiable natural person (i.e. the Data
            Subject), to the extent that such information is protected as
            personal data under applicable Data Protection Laws and is submitted
            by the School or its students (”<strong>School Data</strong>”)
            through the Products.
          </li>
          <li>
            ”<strong>Process</strong>” or ”<strong>Processing</strong>” means
            any operation or set of operations which is performed upon Personal
            Data, whether or not by automatic means, such as collection,
            recording, organization, structuring, storage, adaptation or
            alteration, retrieval, consultation, use, disclosure by
            transmission, dissemination or otherwise making available, alignment
            or combination, restriction, erasure or destruction.
          </li>
          <li>
            ”<strong>Processor</strong>” means the entity which Processes
            Personal Data on behalf of the Controller.
          </li>
          <li>
            “<strong>Products” </strong>means the Offerings as defined in the
            EULA.
          </li>
          <li>
            ”<strong>Security Measures</strong>” has the meaning given in
            Section 5.1.
          </li>
          <li>
            ”<strong>Subprocessor</strong>” means any entity engaged by us to
            Process Personal Data in connection with the Products.
          </li>
          <li>
            ”<strong>Supervisory Authority</strong>” means an independent public
            authority which is established by an EU/EEA Member State or
            Switzerland or the United Kingdom pursuant to Data Protection Laws.
          </li>
        </ol>

        <h3 id="heading2">II. PROCESSING OF PERSONAL DATA</h3>
        <ol type="A">
          <li>
            <strong>
              Roles of the Parties and our Processing of Personal Data.
            </strong>
            &nbsp;The parties acknowledge and agree that with regard to the
            Processing of Personal Data, the School is the Controller, we are
            the Processor. By entering into this DPA, the School instructs us to
            Process Personal Data only in accordance with Data Protection Laws:
            (a) to provide the Products; (b) as further specified through the
            School’s use of the Products (including through use of preference
            options and other functionality of the Products); (c) as documented
            in the EULA, including this DPA; and (d) as further documented in
            any other written instructions given by the School and acknowledged
            by us as constituting instructions for purposes of this DPA
            (together the “<strong>Purpose</strong>“). We may condition the
            acknowledgement described in (d) on the payment of additional fees
            or the acceptance of additional terms. We act on behalf of and on
            the instructions of the School in carrying out the Purpose including
            with regard to transfers of Personal Data outside the EU/EEA, unless
            EU/EEA Member State law or Swiss law to which we are subject
            requires other Processing of School Data by us, in which case we
            will inform the School (unless that law prohibits us from doing so
            on important grounds of public interest) via the Notification Email
            Address.
          </li>
          <li>
            <strong>School’s Processing of Personal Data.</strong>&nbsp;The
            School shall, in its use of the Products and provision of
            instructions, Process Personal Data in accordance with the
            requirements of applicable Data Protection Law. The School shall
            have sole responsibility for the accuracy, quality, and legality of
            Personal Data and the means by which the School acquired Personal
            Data.
          </li>
          <li>
            <strong>Details of the Processing.</strong>
            <ol>
              <li>
                <strong>Duration of the Processing.</strong>&nbsp;The Term plus
                the period from the expiry of the Term until deletion of all
                School Data by us in accordance with the DPA.
              </li>
              <li>
                <strong>Purpose of the Processing.</strong>&nbsp;We will process
                School Data for the purposes of providing the Products to the
                School in accordance with the DPA and as defined under Section
                II.A above.
              </li>
              <li>
                <strong>Types of Personal Data Processed.</strong>&nbsp;The
                types of Personal Data processed is set forth in Annex
                I.&nbsp;&nbsp;
              </li>
              <li>
                <strong>Categories of Data Subjects Processed.</strong>&nbsp;The
                categories of Data Subjects are set forth in Annex I.
              </li>
            </ol>
          </li>
        </ol>

        <h3 id="heading3">III. RIGHTS OF DATA SUBJECTS</h3>
        <ol type="A">
          <li>
            <strong>Data Subject Requests.</strong>&nbsp;We shall, to the extent
            legally permitted, promptly notify the School if we receive any
            requests from a Data Subject to exercise rights in accordance with
            Data Protection Laws (each, a “<strong>Data Subject Request</strong>
            ”). Taking into account the nature of the Processing, we shall
            assist the School by appropriate technical and organizational
            measures, insofar as this is possible, for the fulfilment of the
            School’s obligation to respond to a Data Subject Request under
            applicable Data Protection Laws. To the extent legally permitted,
            the School shall be responsible for any costs arising from our
            provision of such assistance, including any fees associated with
            provision of additional functionality.
          </li>
        </ol>

        <h3 id="heading4">IV. SUBPROCESSORS</h3>
        <ol type="A">
          <li>
            <strong>Appointment of Subprocessors.</strong>&nbsp;The School
            acknowledges and agrees that we may engage third party Subprocessors
            in connection with the provision of the Products. We will enter into
            a written agreement with each Subprocessor containing data
            protection obligations that provide the same data protection
            obligations for Personal Data as those in this DPA, taking into
            account the nature of the Products provided by such Subprocessor.
          </li>
          <li>
            <strong>
              List of Current Subprocessors and Notification of New
              Subprocessors.
            </strong>
            &nbsp;A current list of Subprocessors for the Products, is available
            upon request. We shall inform the School of any intended changes
            concerning the addition or replacement of other Subprocessors,
            thereby giving the School the opportunity to object to such changes.
          </li>
          <li>
            <strong>Liability.</strong>&nbsp;We shall be liable for the acts and
            omissions of its Subprocessors to the same extent we would be liable
            if performing the Products of each Subprocessor directly under the
            terms of this DPA.
          </li>
        </ol>

        <h3 id="heading5">V. SECURITY</h3>
        <ol type="A">
          <li>
            <strong>Our Security Measures.</strong>&nbsp;We will implement and
            maintain technical and organizational measures to protect School
            Data against accidental or unlawful destruction, loss, alteration,
            unauthorized disclosure or access (the ”
            <strong>Security Measures</strong>”) in accordance with Data
            Protection Laws, including as set forth in Annex II. We may update
            or modify the Security Measures from time to time provided that such
            updates and modifications do not result in the degradation of the
            overall security of the Personal Data.
          </li>
          <li>
            <strong>Security Compliance by Our Staff.</strong>&nbsp;We will take
            appropriate steps to ensure compliance with the Security Measures by
            its staff to the extent applicable to their scope of performance,
            including ensuring that all such persons it authorizes to Process
            School Personal Data have committed themselves to confidentiality.
          </li>
          <li>
            <strong>Our Security Assistance.</strong>&nbsp;The School agrees
            that we will (taking into account the nature of the Processing of
            School Personal Data and the information available to us) assist the
            School in ensuring compliance with any of the School’s obligations
            in respect of security of Personal Data (including in case of a
            Personal Data security incident), in accordance with applicable Data
            Protection Laws, by implementing and maintaining Security Measures.
            A current overview of our Security Measures will be provided to the
            School upon request.
          </li>
        </ol>

        <h3 id="heading6">
          VI. SCHOOL DATA INCIDENT MANAGEMENT AND NOTIFICATION
        </h3>
        <ol type="A">
          <li>
            <strong>Incident Notification.</strong>&nbsp;We shall notify the
            School of any security incident relating to School Personal Data
            (within the meaning of applicable Data Protection Law) of which we
            become aware and which may require a notification to be made to a
            Supervisory Authority or Data Subject under applicable Data
            Protection Law or which we are required to notify to the School
            under applicable Data Protection Law (a ”
            <strong>School Data Incident</strong>”). We shall provide
            commercially reasonable cooperation and assistance in identifying
            the cause of such School Data Incident and take commercially
            reasonable steps to remediate the cause to the extent the
            remediation is within our control. The obligations herein shall not
            apply to security incidents with regard to any Personal Data not
            processed by us on behalf of the School under his DPA.
          </li>
          <li>
            <strong>Delivery of Notification.</strong>&nbsp;Notification(s) of
            any School Data Incidents will be delivered to a Notification Email
            Address established by the School. The School is solely responsible
            for ensuring that the Notification Email Address is current and
            valid.
          </li>
          <li>
            <strong>No Assessment of School Data by Us.</strong>&nbsp;We will
            not assess the contents of School Data in order to identify
            information subject to any specific legal requirements. The School
            is solely responsible for complying with legal requirements for
            incident notification applicable to the School and fulfilling any
            third party notification obligations related to any School Data
            Incident(s).
          </li>
          <li>
            <strong>No Acknowledgement of Fault by Us.</strong>&nbsp;Our
            notification of or response to a School Data Incident under this
            Section VI will not be construed as an acknowledgement by us of any
            fault or liability with respect to the School Data Incident.
          </li>
        </ol>

        <h3 id="heading7">VII. RETURN AND DELETION OF SCHOOL DATA</h3>
        <ol type="A">
          <li>
            <strong>Deletion Upon Termination.&nbsp;</strong>Upon termination of
            the EULA under which we are Processing Personal Data, we shall, upon
            the School’s request, and subject to the limitations of the School’s
            selected Products, return all School Data and copies of such data to
            the School or securely destroy them and demonstrate to the
            satisfaction of the School that it has taken such measures, unless
            applicable law prevents it from returning or destroying all or part
            of School Data. We may only continue to retain the School Data after
            such date if permitted by the applicable laws to which it is
            subject.
          </li>
        </ol>

        <h3 id="heading8">VIII. DEMONSTRATION OF COMPLIANCE</h3>
        <ol type="A">
          <li>
            No more than once per contract year as required by relevant Data
            Protection Laws and data protection authorities, during our regular
            business hours and at the School’s sole expense, we will make
            available to the School all information necessary to demonstrate
            compliance with the obligations laid down in this DPA and allow for
            and contribute to audits, including inspections, conducted by the
            School, pursuant to the agreement of the parties and a
            confidentiality agreement to our satisfaction, in a reasonable time
            and manner. As permitted by relevant law, we may fulfill this
            inspection or audit request by providing the School with an
            executive summary or SO2 or SOC2-successor or equivalent report by a
            reputable, independent third party. All information accessed or
            obtained by the School in connection with this audit shall be
            considered our confidential information.
          </li>
          <li>
            We shall inform the School in compliance with and as required by
            relevant Data Protection Laws, if, in its opinion, a Purpose or any
            documented instruction it receives from the School with respect to
            the Processing of Personal Data, infringes or prevents it from
            complying with Data Protection Laws.
          </li>
        </ol>

        <h3 id="heading9">IX. LIMITATION OF LIABILITY</h3>
        <ol type="A">
          <li>
            <strong>Liability under the EULA.</strong>&nbsp;Each party’s
            liability, taken together in the aggregate, arising out of or
            related to this DPA, whether in contract, tort or under any other
            theory of liability, is subject to the ‘Limitation of Liability’
            section of the EULA, and any reference in such section to the
            liability of a party means the aggregate liability of that party and
            its affiliates under the EULA and all DPAs together. For the
            avoidance of doubt, our total liability for all claims from the
            School arising out of or related to the EULA and this DPA shall
            apply in the aggregate for all claims under both the EULA and any
            DPA established under the EULA, including by multiple schools within
            a district, and, in particular, shall not be understood to apply
            individually and severally to schools within a district that is a
            contractual party to any such DPA.
          </li>
        </ol>

        <h3 id="heading10">
          X. SPECIFIC PROVISIONS IN THE EU/EEA, UNITED KINGDOM, AND SWITZERLAND
        </h3>
        <ol type="A">
          <li>
            <strong>
              Transfers of School Data outside the EU/EEA or Switzerland.
            </strong>
            &nbsp;If School Data are stored and/or Processed outside the EU/EEA
            or Switzerland:
            <ol type="1">
              <li>
                To the extent legally required, with respect to Personal Data
                transferred from the EEA and Switzerland, the parties are deemed
                to have signed the clauses issued pursuant to the EU Commission
                Implementing Decision (EU) 2021/914 of 4 June 2021 on standard
                contractual clauses for the transfer of personal data to third
                countries pursuant to Regulation (EU) 2016/679 of the European
                Parliament and of the Council, available at
                http://data.europa.eu/eli/dec_impl/2021/914/oj (“
                <strong>2021 Standard Contractual Clauses</strong>”), which form
                part of this DPA, and will be deemed completed as
                follows:&nbsp;&nbsp;
                <ol type="i">
                  <li>
                    School acts as controller and we act as processor and Module
                    2 of the 2021 Standard Contractual Clauses applies to
                    transfers of Personal Data from School to us;
                  </li>
                  <li>
                    Clause 7 (the optional docking clause) is not included;
                  </li>
                  <li>
                    Under Clause 9 (Use of sub-processors), the parties select
                    Option 2 (General written authorization). We shall provide
                    notice of updates to School at least ten (10) days in
                    advance of any intended additions or replacements of
                    sub-processors.
                  </li>
                  <li>
                    Under Clause 11, the optional language requiring that data
                    subjects be permitted to lodge a complaint with an
                    independent dispute resolution body does not apply;
                  </li>
                  <li>
                    Under Clause 17, the parties choose Option 1 (the law of an
                    EU Member State that allows for third-party beneficiary
                    rights).&nbsp; The parties select the laws of Ireland;
                  </li>
                  <li>
                    Under Clause 18, the parties select the courts of
                    Ireland;&nbsp;
                  </li>
                  <li>
                    Annexes I and II of the 2021 Standard Contractual Clauses
                    are set forth below.
                  </li>
                </ol>
              </li>
              <li>
                With respect to transfers of Personal Data that are subject to
                the Switzerland Federal Act on Data Protection (“FADP”), the
                2021 Standard Contractual Clauses shall be deemed to have the
                following differences to the extent required by the FADP:&nbsp;
                <ol type="i">
                  <li>
                    References to the GDPR are to be understood as references to
                    the FADP insofar as the data transfers are subject
                    exclusively to the FADP and not to the GDPR.&nbsp;
                  </li>
                  <li>
                    The term “member state” shall not be interpreted to exclude
                    data subjects in Switzerland from the possibility of suing
                    for their rights in their place of habitual residence
                    (Switzerland) in accordance with Clause 18(c).&nbsp;
                  </li>
                  <li>
                    References to personal data in 2021 Standard Contractual
                    Clauses also refer to data about identifiable legal entities
                    until the entry into force of revisions to the FADP that
                    eliminate this broader scope.&nbsp;
                  </li>
                  <li>
                    Under Annex I(C): Where the transfer is subject exclusively
                    to the FADP, the supervisory authority is the Swiss Federal
                    Data Protection and Information Commissioner. Where the
                    transfer is subject to both the FADP and the GDPR, the
                    supervisory authority is the Swiss Federal Data Protection
                    and Information Commissioner insofar as the transfer is
                    governed by the FADP, and the supervisory authority is as
                    set forth in the 2021 Standard Contractual Clauses insofar
                    as the transfer is governed by the GDPR.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>
              Transfers of School Data outside the United Kingdom.
            </strong>
            &nbsp;With respect to School Data transferred from the United
            Kingdom for which UK Data Protection Law (and not the law in any
            European Economic Area&nbsp; jurisdiction or Switzerland) governs
            the international nature of the transfer, and such law permits use
            of the 2010 Standard Contractual Clauses but does not permit use of
            the 2021 Standard Contractual Clauses, the 2010 Standard Contractual
            Clauses (decision 2010/87/EU&nbsp;&nbsp;
            <a
              href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32010D0087&amp;from=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>available here</strong>
            </a>
            <strong>) </strong>form part of this DPA and take precedence over
            the rest of this DPA to the extent of any conflict and shall be
            deemed completed as follows:
            <ol>
              <li>
                The “exporter” is the School. The “importer” is Liminex, and our
                contact information is set forth herein. Where Clause 9 of the
                2010 Standard Contractual Clauses requires specification of the
                law that governs the Clauses, the parties select the law of the
                United Kingdom. The “illustrative indemnification clause”
                labelled “optional” is deemed stricken.
                <br />
                Appendices 1 of the 2010 Standard Contractual Clauses are
                completed as set forth in Annex IA and IB below. Appendix 2 is
                complete as set forth in Annex II below. By agreeing to this
                DPA, the Parties are deemed to be signing the 2010 Standard
                Contractual Clauses and its applicable Appendices.
              </li>
              <li>
                We shall maintain our membership in and comply with the EU-U.S.
                and Swiss-U.S. Privacy Shield Frameworks with respect to such
                School Data transferred under such Frameworks.
              </li>
            </ol>
          </li>
        </ol>

        <h2 id="heading20" className="text-center">
          Schedule A
        </h2>
        <p className="text-center">
          Annexes I and II of the 2021 Standard Contractual Clauses
        </p>

        <h3 id="heading21">ANNEX I</h3>
        <h4>A. LIST OF PARTIES</h4>
        <p>
          <b>Data exporter(s):</b>
          <br />
          <b>Name: </b>The entity identified as “School” in the EULA.
          <br />
          <b>Address: </b>The address for School associated with its account or
          as otherwise specified in the EULA or other Agreement with us.
          <br />
          <b>Contact person’s name, position and contact details: </b>The
          contact details associated with its account or as otherwise specified
          in the EULA or other Agreement with us.
          <br />
          <b>
            Activities relevant to the data transferred under these Clauses:{' '}
          </b>
          The processing activities set forth in the EULA, including our
          provision of the Products to the School.
          <br />
          <b>Signature and date: </b>By using the Products, the data exporter
          will be deemed to have signed this Annex I.
          <br />
          <b>Role (controller / processor): </b>Controller
        </p>
        <p>
          <b>Data importer(s):</b>
          <br />
          <b>Name: </b>Liminex as identified in the EULA
          <br />
          <b>Address: </b>The address for us as specified in the EULA.
          <br />
          <b>Contact person’s name, position and contact details: </b>The
          contact details for us as specified in the EULA<b>.</b>
          <br />
          <b>
            Activities relevant to the data transferred under these Clauses:{' '}
          </b>
          The processing activities set forth in the EULA, including our
          provision of the Products to the School.
          <br />
          <b>Signature and date: </b>By providing the Products on School’s
          instructions, the
          <br />
          data importer will be deemed to have signed this Annex I.
          <br />
          <b>Role (controller / processor): </b>Processor
        </p>

        <h4>B. DESCRIPTION OF TRANSFER</h4>
        <p>
          Categories of data subjects whose personal data is transferred:&nbsp;
        </p>
        <ul>
          <li>
            School officials, teachers, student end users and other individuals
            (such as parents) about whom data is provided to us via our Products
            by (or at the direction of) the School or by end users.
          </li>
        </ul>
        <p>Categories of personal data transferred:&nbsp;</p>
        <ul>
          <li>
            Data relating to school officials, educators, student end users, or
            other individuals (such as parents) provided to us via the Products,
            by (or at the direction of) the School or by student end users. The
            Personal Data provided may include: (a) product set up information,
            including number of devices, number of students, and network
            configuration, and a password; (b) information about School
            personnel and teachers and their permission levels in our Products;
            (c) requests submitted for Product support; (d) chats and video
            conferences within GoGuardian Teacher; (e) unique identifiers
            generated by us, and other relevant unique identifiers (f) a
            student’s school-managed account information: student’s name, email
            address, Google Profile ID, Google Image URL, organizational unit,
            and device identifiers necessary to associate a student with a
            certain device, unique/account identifiers for students generated by
            us; (g) depending on Products and features selected by the School,
            the student’s browsing history, IP address, online content,
            snapshots, grade and attendance information, demographic
            information, and assessment information; (h) if the School chooses
            to integrate us with another school software system, unique
            identifiers necessary to connect our systems; (i) geographic
            location of devices for the purpose of the School locating and
            recovering its devices using GoGuardian Admin; (and) (j)
            parent’s/guardian’s name, email address, and optionally phone number
            from the School.
          </li>
        </ul>
        <p>
          Sensitive data transferred (if applicable) and applied restrictions or
          safeguards that fully take into consideration the nature of the data
          and the risks involved, such as for instance strict purpose
          limitation, access restrictions (including access only for staff
          having followed specialised training), keeping a record of access to
          the data, restrictions for onward transfers or additional security
          measure:
        </p>
        <ul>
          <li>
            None anticipated, but the Products do not impose a technical
            restriction on the categories of Personal Data above provided
            through the Products.
          </li>
        </ul>
        <p>
          The frequency of the transfer (e.g. whether the data is transferred on
          a one-off or continuous basis):&nbsp;
        </p>
        <ul>
          <li>
            On a continuous basis for as long as School is engaging us to
            provide the Products.
          </li>
        </ul>
        <p>
          Nature of the processing: The nature of the Processing is as forth in
          the EULA or other agreement between the parties.&nbsp;
        </p>
        <p>Purpose(s) of the data transfer and further processing:&nbsp;</p>
        <ul>
          <li>
            The purposes for the data transfer are to facilitate our provision
            of Products pursuant to the EULA or other agreement between the
            parties.
          </li>
        </ul>
        <p>
          The period for which the personal data will be retained, or, if that
          is not possible, the criteria used to determine that
          period:&nbsp;&nbsp;
        </p>
        <ul>
          <li>
            The data will be retained for the time period needed to accomplish
            the purposes of processing, unless otherwise required by applicable
            law.
          </li>
        </ul>
        <p>
          For transfers to (sub-) processors, also specify subject matter,
          nature and duration of the processing:&nbsp;
        </p>
        <ul>
          <li>
            Transfers to subprocessors are for the same purposes as transfers to
            the processor.
          </li>
        </ul>
        <br />
        <h4>C. COMPETENT SUPERVISORY AUTHORITY</h4>
        <p>
          Identify the competent supervisory authority/ies in accordance with
          Clause 13:&nbsp; Ireland Data Protection Commissioner
        </p>

        <h3 id="heading22">
          ANNEX II – TECHNICAL AND ORGANISATIONAL MEASURES INCLUDING TECHNICAL
          AND ORGANISATIONAL MEASURES TO ENSURE THE SECURITY OF THE DATA
        </h3>
        <p>
          <i>
            Description of the technical and organisational measures implemented
            by the data importer(s) (including any relevant certifications) to
            ensure an appropriate level of security, taking into account the
            nature, scope, context and purpose of the processing, and the risks
            for the rights and freedoms of natural persons.
          </i>
        </p>
        <h4>Access Control and Management</h4>
        <p>
          We implement lifecycle management practices to ensure that all access
          is promptly terminated when user access is no longer necessary. Only
          authorized users are granted access to our information systems, and
          users are limited to specific defined, documented, and approved levels
          of access rights consistent with the least privilege principle.
        </p>
        <p>
          Access to our information system is granted based on a valid
          need-to-know that is determined by assigned official duties and
          satisfying all personnel security criteria, separation of duties and
          intended system usage. Proper identification and approval is required
          for requests to establish system accounts.
        </p>
        <p>
          Information must be classified and protected according to its risk
          profile. Any data classified as Confidential, Restricted, or with a
          required need for confidentiality or integrity, are stored on
          encrypted systems.
        </p>
        <h4>Data Recovery &amp; Logging</h4>
        <p>
          As part of the our Disaster Recovery Plan, we implement a backup
          solution to protect the availability of its information.
        </p>
        <p>
          We retain system audit logs and records to the extent needed to enable
          the monitoring, analysis, investigation, and reporting of unlawful or
          unauthorized system activity. Network and Host-Based Intrusion
          Detection Systems are to be used, where applicable.
        </p>
        <h4>Configuration and Change Management</h4>
        <p>
          We implement a configuration management plan to meet the compliance
          requirements for each system and a change management program to track,
          review, approve, or disapprove, and log changes. We control and
          monitor user-installed software.
        </p>
        <h4>Incident Response</h4>
        <p>
          Our Incident Response Team responds to security incidents in
          accordance with our Incident Response Plan.
        </p>
        <h4>Media Protection</h4>
        <p>
          We protect removable and portable storage media that stores or
          processes non-public data with necessary measures, such as encryption,
          to ensure non-public data is prohibited from unauthorized access and
          disclosure.{' '}
        </p>
        <h4>Physical Protection</h4>
        <p>
          Our facilities maintain a list of individuals with authorized access
          to our offices. Our facilities and offices are protected by physical
          security measures to ensure information assets are physically
          protected from damage, unauthorized access, theft, and interruptions
          to business processes. Employees will have access to systems and
          facilities removed upon termination of employment.
        </p>
        <h4>Risk &amp; Vulnerability Management</h4>
        <p>
          We, from time to time, identify reasonably foreseeable internal and
          external threats that could result in unauthorized disclosure, misuse,
          alteration, or destruction of organization owned or managed
          information or information systems. Areas of physical, administrative,
          and technical risks are assessed for potential impact and likelihood.
          Security controls in place to mitigate the resultant risks of
          identified threats are evaluated. We regularly conduct vulnerability
          scanning and testing and identify steps to reduce risk.{' '}
        </p>
        <p>
          The installation of new types of equipment that are not currently on
          the approved list of network devices for our infrastructure network
          must be approved by a group prior to installation.{' '}
        </p>
        <h4>Backups &amp; Recovery: </h4>
        <p>
          We implement a backup solution to protect the availability of its
          information. The frequency of information system backups and the
          transfer rate of backup information to an alternate storage site (if
          so designated) shall be consistent with our recovery objectives and
          internal policies.{' '}
        </p>
        <p>
          We regularly perform data backups for all appropriate systems. All
          backup solutions and media are tested at least annually or as needed
          to ensure the highest integrity and availability.{' '}
        </p>
        <h4>System and Information Integrity </h4>
        <p>
          All endpoints capable of running anti-virus software must have
          installed company-approved and licensed anti-malware software.
          Vendor-supplied security updates, service packs, and patches are
          implemented on production systems in a timely fashion. Firewalls are
          used to protect the internal network resources from public networks
          such as the Internet.{' '}
        </p>
        <p>
          We implement an encryption management program to use proven,
          government-approved, FIPS-validated encryption technologies in all IT
          systems that process, store, and transmit non-public data.{' '}
        </p>
        <p>
          When sensitive information is transmitted over any publicly accessible
          communication network, it must be sent in encrypted form.{' '}
        </p>
        <h4>Training &amp; Compliance</h4>
        <p>
          We conduct an IT Security Awareness and Training program that outlines
          the types and frequency of training sessions for all employees that
          meet organizational, security, compliance, and contractual
          requirements.
        </p>
        <p>
          Users are required to observe and follow our policies, standards, and
          procedures at all times. Audits of compliance with this standard are
          performed on a regular basis.{' '}
        </p>
      </div>{' '}
    </BodyContentWrapper>
  )
}

export default EeaPolicyContent
